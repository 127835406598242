import React, { useEffect, useState } from 'react'
import styled, { keyframes } from 'styled-components'
import classNames from 'classnames'
import { useLocation } from '@gatsbyjs/reach-router'
import RTGLink from '@components/shared/link'
import { array, bool } from 'prop-types'
import '@assets/css/components/search/connectors/infinite-hits.sass'
import ProductTile from '@components/product/product-tile'
import Banner from '@templates/strapi-cms/content-types/Banner'
import { LoadingSpinner } from '@components/shared/LoadingSpinner'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

/* These values came from the styles that were being applied by the 'large-3, medium-6, small-12 foundation grid classes. */
const tileOuterSpacing = '1.25rem'
const tileWidth = {
  SMALL: '100%',
  MEDIUM: '33.33333%',
  LARGE: '25%',
}

const TileWrapper = styled.div`
  width: calc(${tileWidth.SMALL}) !important;
  margin-left: 0 !important;
  margin-right: 0 !important;

  @media print, screen and (min-width: 750px) {
    width: calc(${tileWidth.MEDIUM} - ${tileOuterSpacing}) !important;
    margin: calc(${tileOuterSpacing} / 2) !important;
  }
`

const NoResults = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
  align-items: center;
`
const NoResultsMessageTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
`

const NoResultsMessage = styled.p`
  color: #6e6e6e;
  margin-top: 10px;
`

const LoadingSpinnerWrapper = styled.div`
  height: 3rem;
  width: 100%;
`

const getSimilarProductsLink = path => {
  const pathArr = path.split('/')
  if (pathArr[1] === 'furniture' && pathArr[2]) {
    return `${pathArr[1]}/${pathArr[2]}`
  }

  if (pathArr[1] === 'mattress') return '/mattress'

  return '/furniture'
}

const Hits = React.memo(({ hits, filtersChanged, searching }) => {
  const [isShopCollectionEnabled, setShopCollectionEnabled] = useState(false)
  const location = useLocation()
  const isShopCollectionFeatureEnabled = useFeatureIsOn('shop-collection')

  useEffect(() => {
    // trackProductImpressions
    if (window && window.dataLayer) {
      const productSkus = hits.map(hit => hit.sku)
      window.dataLayer.push({ event: 'ee_impression', ecommerce: { impressions: productSkus } })
    }
  }, [hits])

  useEffect(() => {
    if (isShopCollectionFeatureEnabled) {
      setShopCollectionEnabled(true)
    } else {
      setShopCollectionEnabled(false)
    }
  }, [isShopCollectionFeatureEnabled])

  if (hits.length === 0) {
    return (
      <NoResults>
        {searching ? (
          <LoadingSpinnerWrapper>
            <LoadingSpinner />
          </LoadingSpinnerWrapper>
        ) : (
          <>
            <NoResultsMessageTitle>Sorry, we couldn't find any results under this category.</NoResultsMessageTitle>
            <NoResultsMessage>But you can find the similar products by clicking the following link.</NoResultsMessage>
            <br />
            <RTGLink
              data={{
                slug: location?.pathname ? getSimilarProductsLink(location.pathname) : '/furniture',
                title: `see-similar-products`,
                category: 'plp',
                action: 'click',
                label: `See similar products`,
              }}
            >
              See similar products
            </RTGLink>
          </>
        )}
      </NoResults>
    )
  }

  return (
    <div
      id="productResultsWrapper"
      role="region"
      aria-label="Product Results"
      aria-describedby="productListCount"
      tabIndex="-1"
      className="search-wrapper"
    >
      <div className="ais-InfiniteHits">
        <div className="ais-InfiniteHits-list grid-x">
          {hits.map((tile, index) => (
            <TileWrapper key={tile.sku || tile.tileIndex} className={classNames('ais-InfiniteHits-item cell')}>
              {tile.isPLPBanner ? (
                <Banner data={tile} isPLPBanner />
              ) : (
                <ProductTile
                  data={tile}
                  id={`product-title:${tile?.sku}`}
                  index={index}
                  viewType="grid"
                  source="search"
                  last={!filtersChanged && index === hits.length - 1}
                  antiFlickering={false}
                  showSwatches
                  shopCollection={isShopCollectionEnabled}
                  featureVariant="plp"
                />
              )}
            </TileWrapper>
          ))}
        </div>
      </div>
    </div>
  )
})

Hits.displayName = 'Hits'
Hits.propTypes = {
  hits: array,
  filtersChanged: bool,
  searching: bool,
}

export default Hits
