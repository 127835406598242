// dependencies
import React, { useMemo, useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Grid, Box, styled, Link as MuiLink } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import _isArray from 'lodash/isArray'
import _isEmpty from 'lodash/isEmpty'
import _split from 'lodash/split'
import _get from 'lodash/get'
import _map from 'lodash/map'
// context
import { useStrapiContext } from '@context/strapiContext'
import { useGrowthBook, useFeatureValue } from '@growthbook/growthbook-react'
// components
import ProductTile from '@components/product/product-tile'
import Pagination from '@components/plp/plp-parts/Pagination'
import useScrollToFragment from '@hooks/useScrollToFragment'
import Coupon from './Coupon'
import CategoryTitle from './CategoryTile'
import StrapiCategoryTileV2 from './CategoryTileV2'
import CollectionTile from './CollectionTile'
import CollectionTileImageGallery from './CollectionTileImageGallery'
import Markdown from './Markdown'
import StrapiHeader from './Header'
import useSeeMore from '../../../lib/helpers/useSeeMore'

const useStyles = makeStyles(t => ({
  gridAndHeaderWrapper: ({ styles }) => ({
    paddingTop: styles?.paddingTopDesktop,
    paddingBottom: styles?.paddingBottomDesktop,
    [t.breakpoints.down('xs')]: {
      paddingTop: styles?.paddingTopMobile,
      paddingBottom: styles?.paddingBottomMobile,
    },
  }),
  mobileGrayBackgroundWrapper: ({ backgroundColorMobile }) => ({
    margin: '0 -15px',
    padding: '0 15px',
    backgroundColor: backgroundColorMobile ?? 'inherit',
    width: 'calc(100% + 30px) !important',
  }),
  gridContainer: ({ styles }) => ({
    paddingTop: 0,
    paddingBottom: 0,
    width: `calc(100% + ${t.spacing(styles.spacing)}px)`,
    maxWidth: `calc(100% + ${t.spacing(styles.spacing)}px)`,
    marginLeft: `-${Math.floor(t.spacing(styles.spacing) / 2)}px`,
    marginBottom: `0px`,
  }),
}))

export const SeeMoreWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
}))

export const SeeMoreButton = styled(MuiLink)(({ theme }) => ({
  fontSize: '16px',
  minHeight: '21px',
  lineHeight: '21px',
}))

export const DesktopTileGridWrapper = styled('div')(
  ({
    theme,
    desktopColumns,
    isExpandedDesktop,
    spaceBetweenDesktop = 60,
    collapsedRows,
    isCollapsableDesktop,
    IsCollapsableMobile,
    columnsMobile,
    columnsMobileSpacing,
  }) => ({
    display: 'grid',
    gridTemplateRows: isCollapsableDesktop && `repeat(${collapsedRows}, 1fr)`,
    gridTemplateColumns: isCollapsableDesktop
      ? `repeat(${desktopColumns}, 1fr)`
      : `repeat(auto-fit, calc(${100 / desktopColumns}% - ${5 * desktopColumns + 5}px))`,
    gridAutoRows: isExpandedDesktop ? 'auto' : 0,
    overflowY: isExpandedDesktop ? 'visible' : 'hidden',
    columnGap: `${spaceBetweenDesktop}px`,
    '& > *': {
      marginBottom: '35px',
      maxWidth: '100% !important',
    },
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: `repeat(auto-fit, calc(${100 / columnsMobile}% - ${15 * columnsMobileSpacing}px))`,
      gridTemplateRows: IsCollapsableMobile && `repeat(${collapsedRows}, 1fr)`,
    },
  }),
)

/**
 * Normalize Category
 * @param {string} category - the category string (e.g. "Living Room")
 * @returns {string} The normalized string (e.g. "livingroom")
 */
export const normalizeCategory = (category = '') => {
  if (!category || typeof category !== 'string') return ''
  const lowercaseWord = category.toLowerCase()
  const spaceRemovedWord = lowercaseWord.replace(/\s/g, '')
  const singularizedWord = spaceRemovedWord.replace(/s$/, '')
  return singularizedWord
}

/**
 * Determine Property
 * @param {object} obj - the grid object containing the tiles
 * @param {array} pathArr - the property path (e.g. "CategoryTile.PersonalizationCategory.filters.category")
 * @returns {array} The given property value at the given path
 */
export const determineProperty = (obj, pathArr) => {
  if (!obj || typeof obj !== 'object' || !pathArr || pathArr.length === 0) return null
  if (pathArr.length === 1) return obj[pathArr[0]]
  return determineProperty(obj[pathArr[0]], pathArr.slice(1))
}

/**
 * Sort User Categories
 * @param {array} gridContent - the grid object containing the tiles
 * @param {object} rtgUserContext - object containing categories array
 * @param {object} personalizationJson - growthbook object, e.g. {
      userContentField: 'productCategories',
      contentSortPath: 'CategoryTile.PersonalizationCategory.filters.category',
    }
 * @returns {array} new gridContent sorted by the personalization and usercontext data
 */
export const sortUserCategories = (gridContent, rtgUserContext, personalizationJson) => {
  const { userContentField, contentSortPath } = personalizationJson || {}
  const rtgUserContextArr = rtgUserContext?.[userContentField]

  if (!contentSortPath || !Array.isArray(rtgUserContextArr) || !rtgUserContextArr.length) return gridContent

  const elementIndexMap = new Map()
  rtgUserContextArr?.forEach((category, index) => elementIndexMap.set(normalizeCategory(category), index))
  const sortedContent = gridContent.slice().sort((a, b) => {
    const categoryArrA = determineProperty(a, contentSortPath.split('.', 2))
    const categoryArrB = determineProperty(b, contentSortPath.split('.', 2))
    const categoryIndexA = categoryArrA?.find(category =>
      elementIndexMap.has(normalizeCategory(category?.filters?.category)),
    )
    const categoryIndexB = categoryArrB?.find(category =>
      elementIndexMap.has(normalizeCategory(category?.filters?.category)),
    )
    if (categoryIndexA && !categoryIndexB) return -1
    if (!categoryIndexA && categoryIndexB) return 1
    if (categoryIndexA && categoryIndexB) {
      return (
        elementIndexMap.get(normalizeCategory(categoryIndexA?.filters?.category)) -
        elementIndexMap.get(normalizeCategory(categoryIndexB?.filters?.category))
      )
    }
    return 0
  })
  return sortedContent
}

const StrapiPageGrid = ({ data, contentTabData }) => {
  // Reads URL fragment and scrolls to section
  useScrollToFragment()

  const [currentPage, setCurrentPage] = useState(1)
  const [isExpandedDesktop, _setIsExpandedDesktop] = useState(null)
  const [isExpandedMobile, _setIsExpandedMobile] = useState(null)
  const grid = data?.Grid || {}
  const md = Math.floor(12 / (grid?.Columns || 4))
  const xs = Math.floor(12 / (grid?.MobileColumn || 1))
  const paddings = grid?.Spacing
  const mobilePaddings = grid?.MobileSpacing
  const columns = grid?.Columns || 4
  const columnsMobile = grid?.MobileColumn || 1
  const columnsMobileSpacing = grid?.MobileColumn > 1 ? grid?.MobileColumn : 0
  const isCollapsableDesktop =
    grid?.IsCollapsableDesktop &&
    grid?.CollapsedNumOfRowsDesktop !== null &&
    grid?.Content?.length / columns > grid?.CollapsedNumOfRowsDesktop
  const IsCollapsableMobile =
    grid?.IsCollapsableMobile &&
    grid?.CollapsedNumOfRowsMobile !== null &&
    grid?.Content?.length / columnsMobile > grid?.CollapsedNumOfRowsMobile
  let spacing = 6
  if (contentTabData?.contentTab) spacing = 2
  else if (grid?.NewCategoryTileView) spacing = 3

  const itemsPerPage = grid?.ItemsPerPage || 10
  const totalPages = Math.ceil(grid?.Content?.length / itemsPerPage)
  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const pagination = grid?.Pagination

  const handlePageChange = (key, pageNumber) => {
    setCurrentPage(pageNumber)
  }

  const styles = {
    paddingTopDesktop: paddings?.TopPadding > 0 ? `${paddings?.TopPadding}px` : '0',
    paddingTopMobile: mobilePaddings?.TopPadding > 0 ? `${mobilePaddings?.TopPadding}px` : '0',
    paddingBottomDesktop: paddings?.BottomPadding > 0 ? `${paddings?.BottomPadding}px` : '0',
    paddingBottomMobile: mobilePaddings?.BottomPadding > 0 ? `${mobilePaddings?.BottomPadding}px` : '0',
    spacing,
  }

  const {
    gridContainerRef: gridContainerRefDesktop,
    handleSeeMoreClick: handleSeeMoreClickDesktop,
    isSeeMore: isSeeMoreDesktop,
    isSeeLess: isSeeLessDesktop,
  } = useSeeMore({
    numOfRowsCollapsed: grid?.CollapsedNumOfRowsDesktop,
    isExpanded: isExpandedDesktop,
    _setIsExpanded: _setIsExpandedDesktop,
    isCollapsable: isCollapsableDesktop,
  })

  const {
    gridContainerRef: gridContainerRefMobile,
    handleSeeMoreClick: handleSeeMoreClickMobile,
    isSeeMore: isSeeMoreMobile,
    isSeeLess: isSeeLessMobile,
  } = useSeeMore({
    numOfRowsCollapsed: grid?.CollapsedNumOfRowsMobile,
    isExpanded: isExpandedMobile,
    _setIsExpanded: _setIsExpandedMobile,
    isCollapsable: IsCollapsableMobile,
  })

  const classes = useStyles({ styles, backgroundColorMobile: grid?.BackgroundColorMobile })
  const { disabledCoupons } = useStrapiContext()

  // Personalization variables
  const growthbook = useGrowthBook()
  const rtgUserContext = growthbook?.getAttributes()?.rtgUserContext
  const personalizationJson = useFeatureValue(grid?.Personalization?.GrowthbookFeatureId)

  const isCouponDisabled = useCallback(
    coupon => {
      const coupons = disabledCoupons?.data
      if (_isArray(coupons) && !_isEmpty(coupons)) {
        return coupons.some(
          id =>
            id ===
            (_get(coupon, 'testId', []).includes('coupon:') ? _get(_split(coupon?.testId, ':'), '1') : coupon?.testId),
        )
      }
      return false
    },
    [disabledCoupons],
  )

  const content = useMemo(() => {
    const filteredContent = grid?.Content?.filter(item => {
      if (item?.Coupon && isCouponDisabled(item?.Coupon)) {
        return false
      }
      return true
    })
    const sortedContent = personalizationJson
      ? sortUserCategories(filteredContent, rtgUserContext, personalizationJson)
      : filteredContent
    return sortedContent
  }, [grid.Content, isCouponDisabled, rtgUserContext, personalizationJson])

  if (content && Array.isArray(content)) {
    const gridContent = pagination ? content.slice(startIndex, endIndex) : content
    const renderGrid = () => (
      <>
        {_map(gridContent, (item, index) => {
          const key = `${grid?.id}-${item.__typename}-${index}`.toLowerCase()
          const View = {
            BaseCoupon: item?.Coupon && <Coupon data={item?.Coupon} />,
            BaseCategoryTile:
              item?.CategoryTile &&
              (grid?.NewCategoryTileView ? (
                <StrapiCategoryTileV2 data={item?.CategoryTile} />
              ) : (
                <CategoryTitle data={item?.CategoryTile} />
              )),
            BaseCollectionTile: item?.CollectionTile && (
              <CollectionTile
                data={item?.CollectionTile}
                collectionTileDesignV2CG={contentTabData?.CollectionTileDesignV2}
              />
            ),
            BaseCollectionTileImageGallery: item?.CollectionTileImageGallery && (
              <CollectionTileImageGallery data={item?.CollectionTileImageGallery} />
            ),
            BaseProductTile: item?.SKU && (
              <ProductTile
                index={index}
                id={`product-title:${item?.SKU}`}
                sku={item?.SKU}
                source="strapi-grid"
                antiFlickering
                showSwatches={false}
                featureVariant="plp"
              />
            ),
            PageMarkdown: item?.Markdown && <Markdown data={item?.Markdown} />,
          }
          return (
            <Grid item md={md} sm={md} xs={xs} key={key}>
              {View[item.__typename] || null}
            </Grid>
          )
        })}
      </>
    )

    if (pagination) {
      return (
        <>
          {grid?.Header && <StrapiHeader data={grid.Header} />}
          <Grid
            container
            spacing={spacing}
            item
            md={12}
            className={classNames(classes.gridContainer, 'grid-wrapper')}
            data-testid={grid?.testId}
          >
            {renderGrid()}
          </Grid>
          {pagination && (
            <div className="pagination">
              <Pagination
                padding={2}
                nbPages={totalPages}
                currentPage={currentPage}
                applyStateChange={handlePageChange}
              />
            </div>
          )}
        </>
      )
    }

    return (
      <>
        {grid?.DisplayDesktop !== false && (
          <Box className={classes.gridAndHeaderWrapper} sx={{ display: { xs: 'none', sm: 'block', md: 'block' } }}>
            {grid?.Header && <StrapiHeader data={grid.Header} />}
            <DesktopTileGridWrapper
              ref={gridContainerRefDesktop}
              spaceBetweenDesktop={30}
              isExpandedDesktop={isCollapsableDesktop !== true || isExpandedDesktop}
              collapsedRows={grid?.CollapsedNumOfRowsDesktop}
              desktopColumns={columns}
              isCollapsableDesktop={isCollapsableDesktop}
            >
              {renderGrid()}
            </DesktopTileGridWrapper>
            {isCollapsableDesktop === true && (
              <SeeMoreWrapper>
                <SeeMoreButton
                  href="#"
                  onClick={isSeeMoreDesktop || isSeeLessDesktop ? handleSeeMoreClickDesktop : null}
                >
                  {isSeeMoreDesktop && 'See More'}
                  {isSeeLessDesktop && 'See Less'}
                </SeeMoreButton>
              </SeeMoreWrapper>
            )}
          </Box>
        )}
        {grid?.DisplayMobile !== false && (
          <Box className={classes.gridAndHeaderWrapper} sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
            <Box className={grid?.BackgroundColorMobile && classes.mobileGrayBackgroundWrapper}>
              <DesktopTileGridWrapper
                ref={gridContainerRefMobile}
                isExpandedDesktop={IsCollapsableMobile !== true || isExpandedMobile}
                collapsedRows={grid?.CollapsedNumOfRowsMobile}
                columnsMobile={columnsMobile}
                columnsMobileSpacing={columnsMobileSpacing}
                IsCollapsableMobile={IsCollapsableMobile}
              >
                {renderGrid()}
              </DesktopTileGridWrapper>

              {IsCollapsableMobile === true && (
                <SeeMoreWrapper>
                  <SeeMoreButton
                    href="#"
                    onClick={isSeeMoreMobile || isSeeLessMobile ? handleSeeMoreClickMobile : null}
                  >
                    {isSeeMoreMobile && 'See More'}
                    {isSeeLessMobile && 'See Less'}
                  </SeeMoreButton>
                </SeeMoreWrapper>
              )}
            </Box>
          </Box>
        )}
      </>
    )
  }

  return null
}

StrapiPageGrid.propTypes = {
  data: PropTypes.object.isRequired,
  contentTabData: PropTypes.object,
}

export default StrapiPageGrid

export const StrapiPageGridFragment = graphql`
  fragment StrapiPageGridFragment on StrapiGrid {
    id
    testId
    Columns
    MobileColumn
    Content {
      __typename
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on BaseCoupon {
        Coupon {
          ...StrapiCouponFragment
        }
      }
      ... on BaseCategoryTile {
        CategoryTile {
          ...StrapiCategoryTileFragment
          PersonalizationCategory {
            filters {
              category
            }
          }
        }
      }
      ... on BaseCollectionTile {
        CollectionTile {
          ...StrapiCollectionTileFragment
        }
      }
      ... on BaseCollectionTileImageGallery {
        CollectionTileImageGallery {
          ...StrapiCollectionTileImageGalleryFragment
        }
      }
      ... on BaseProductTile {
        __typename
        SKU
      }
    }
    Spacing {
      TopPadding
      BottomPadding
    }
    MobileSpacing {
      TopPadding
      BottomPadding
    }
    Header {
      ...StrapiHeaderFragment
    }
    NewCategoryTileView
    DisplayDesktop
    DisplayMobile
    BackgroundColorMobile
    Personalization {
      GrowthbookFeatureId
    }
    Pagination
    ItemsPerPage
    CollapsedNumOfRowsDesktop
    CollapsedNumOfRowsMobile
    IsCollapsableDesktop
    IsCollapsableMobile
  }
`
