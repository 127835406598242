import PropTypes from 'prop-types'
import React from 'react'
import { Stack, Typography } from '@mui/material'
import '@assets/css/components/search/connectors/stats.sass'
import { announce } from '@helpers/aria-announce'
import styled from 'styled-components'
import { isEqual } from 'lodash'
import { breakPoints } from '@constants/styles'
import ShopAndDeliverTo from '@shared/ShopAndDeliverTo'

const StatsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  pointer-events: none;

  @media screen and (min-width: ${breakPoints['large-min']}) {
    position: absolute;
  }
`

export class Stats extends React.Component {
  componentDidMount() {
    const { nbHits } = this.props
    announce(`${nbHits.toLocaleString()} RESULTS`)
  }

  shouldComponentUpdate(nextProps) {
    const { searching: nextSearching, nbHits: nextNBHits } = nextProps
    const { searching, nbHits } = this.props
    return !isEqual(searching, nextSearching) || !isEqual(nbHits, nextNBHits)
  }

  render() {
    const { nbHits, searching, cbFunction } = this.props

    const resultsText = nbHits > 0 ? `${nbHits.toLocaleString()} Results` : `Sorry, no results found.`
    return (
      // TODO: this is a dupe code from searchv2/connectors/ConnectedStats
      <Stack direction="row" alignItems="center" spacing={1} sx={{ maxWidth: '100%' }}>
        <Typography id="productListCount" sx={{ whiteSpace: 'nowrap' }}>
          {searching ? 'Loading...' : resultsText}
        </Typography>
        {/* SET USER DELIVERY ZONE */}
        <ShopAndDeliverTo variant="plp" cbFunction={cbFunction} />
      </Stack>
    )
  }
}

Stats.propTypes = {
  nbHits: PropTypes.number,
  searching: PropTypes.any,
  cbFunction: PropTypes.func,
}

export default Stats
