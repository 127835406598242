import React from 'react'
import { useMediaQuery } from '@mui/material'
import PropTypes from 'prop-types'

import { generateImgData, generateKey, getImageData, getRowHeight, itemRatio, calculateTotalRows } from './helpers'

import {
  ImageListStyles,
  ImageListItemStyles,
  InnerHtmlStyles,
  StrapiLinkSubtitleStyles,
  StrapiLinkTileStyles,
  SectionStyles,
  ImageListItemBarStyles,
  StrapiImageStyles,
} from './MosaicGrid.styles'

const MosaicGrid = ({ mosaicItems, mosaicId, mosaicTitle, displaySettings }) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  const totalRows = displaySettings !== 'Desktop' ? calculateTotalRows(mosaicItems, displaySettings) : 0

  return (
    <ImageListStyles variant="quilted" rows={totalRows}>
      {mosaicItems.map((item, index) => {
        const { TileLink, Image, TileColumnsAndRows, BackgroundColor, FontColor, Heading, DisplayLinkText } =
          item?.MosaicGridTile || {}
        const itemCols = itemRatio[TileColumnsAndRows]?.cols
        const itemRows = itemRatio[TileColumnsAndRows]?.rows
        const headingHtml = Heading?.data?.childMarkdownRemark?.html
        return (
          <ImageListItemStyles
            key={generateKey(mosaicId, TileColumnsAndRows, isMobile, index)}
            itemrationame={TileColumnsAndRows}
            itemratio={itemRatio[TileColumnsAndRows]}
            cols={itemCols}
            rows={itemRows}
            rowheight={getRowHeight(isMobile, itemRows)}
            backgroundcolor={BackgroundColor?.ColorHex}
          >
            {Image && <StrapiImageStyles data={generateImgData(getImageData(Image), mosaicTitle)} noPaddingBottom />}
            <ImageListItemBarStyles
              sx={{ background: 'transparent' }}
              title={
                <MosaicGridTitleSection
                  heading={Heading}
                  tileLink={TileLink}
                  headingHtml={headingHtml}
                  fontcolor={FontColor}
                  displaylinktext={DisplayLinkText}
                />
              }
              position="top"
            />
          </ImageListItemStyles>
        )
      })}
    </ImageListStyles>
  )
}

const MosaicGridTitleSection = ({ heading, tileLink, headingHtml, fontcolor, displaylinktext }) => (
  <SectionStyles>
    {heading && <InnerHtmlStyles fontcolor={fontcolor} html={headingHtml} />}
    {tileLink && (
      <>
        {displaylinktext !== false && (
          <StrapiLinkSubtitleStyles fontcolor={fontcolor}>{tileLink?.DisplayText}</StrapiLinkSubtitleStyles>
        )}
        <StrapiLinkTileStyles data={tileLink} />
      </>
    )}
  </SectionStyles>
)

MosaicGrid.propTypes = {
  mosaicItems: PropTypes.array,
  mosaicId: PropTypes.string,
  mosaicTitle: PropTypes.string,
  displaySettings: PropTypes.string,
}

MosaicGridTitleSection.propTypes = {
  heading: PropTypes.object,
  tileLink: PropTypes.object,
  headingHtml: PropTypes.string,
  fontcolor: PropTypes.object,
  displaylinktext: PropTypes.bool,
}

export default MosaicGrid
