// dependencies
import React from 'react'
import { Box } from '@mui/material'
// components
import ProductTile from '@components/product/product-tile'
import ProductTileMiniWrapper from '@components/product/ProductTileMini/ProductTileMiniWrapper'
import Coupon from '../Coupon'
import CategoryTile from '../CategoryTile'
import CategoryTileV2 from '../CategoryTileV2'
import CategoryTileCircle from '../CategoryTileCircle/CategoryTileCircle'
import CollectionTile from '../CollectionTile'
import StrapiButton from '../Button'
import StrapiMarkdown from '../Markdown'
import { isHero } from './helpers'

const heroCategoryTiles = {
  fontSizeXl: '25px',
  fontSizeLg: '22px',
  fontSizeMd: '16px',
}

const generateItemKey = (Slider, item) => (typeName = '') =>
  `${Slider?.id}-${item.__typename}-${item?.[typeName]?.id || item?.[typeName]}`.toLowerCase()

export const renderCategoryTile = (Slider, item, key, sliderProps, selectedPill, sliderFilterListTitle, index) => {
  if (sliderProps?.displayFilters) {
    return (
      <CategoryTileCircle
        data={item?.CategoryTile}
        width={150}
        mobileWidth={90}
        key={key}
        displayFilters={sliderProps.displayFilters}
        setStateFilters={sliderProps.setStateFilters}
        index={index}
      />
    )
  }
  if (Slider?.NewCategoryTileView) {
    return (
      <CategoryTileV2
        data={item?.CategoryTile}
        key={key}
        titleStyles={isHero(Slider) ? heroCategoryTiles : null}
        selectedPill={selectedPill}
        sliderFilterListTitle={sliderFilterListTitle}
        sliderTitle={Slider?.Title}
      />
    )
  }
  return (
    <Box sx={{ padding: '6px' }} key={key}>
      <CategoryTile data={item?.CategoryTile} />
    </Box>
  )
}

export const renderProductTile = (Slider, index, sku, key, selectedPill, couponSliderSkus) => {
  if (Slider?.NewProductTileView)
    return <ProductTileMiniWrapper sku={sku} index={index} key={key} source="strapi-slider" />

  if (couponSliderSkus?.length > 0) {
    const isCouponProductTile = couponSliderSkus.includes(sku)
    return (
      <Box
        sx={{
          height: '100%',
          width: { xs: '75vw', sm: 'auto' },
          paddingRight: isCouponProductTile ? '6px' : 0,
          '& .MuiCardContent-root': {
            width: 'auto',
          },
        }}
      >
        <ProductTile
          index={index}
          id={`product-title:${sku}`}
          sku={sku}
          source="strapi-slider"
          antiFlickering
          showSwatches
          key={key}
          selectedPill={selectedPill}
          featureVariant="strapi-slider"
        />
      </Box>
    )
  }
  return (
    <>
      <Box
        sx={{
          height: '100%',
          display: { xs: 'none', sm: 'block', md: 'block' },
          '& .MuiCardContent-root': {
            width: 'auto',
          },
        }}
      >
        <ProductTile
          index={index}
          id={`product-title:${sku}`}
          sku={sku}
          source="strapi-slider"
          antiFlickering
          showSwatches
          key={key}
          selectedPill={selectedPill}
          featureVariant="strapi-slider"
        />
      </Box>
      <Box
        sx={{
          height: '100%',
          display: { md: 'none', sm: 'none', xs: 'block' },
          '& .MuiCardContent-root': {
            width: 'auto',
          },
        }}
      >
        <ProductTileMiniWrapper sku={sku} index={index} key={key} source="strapi-slider-mobile" />
      </Box>
    </>
  )
}

export const renderCouponTile = (coupon, key) => (
  <Box
    sx={{
      boxSizing: 'border-box',
      height: '100%',
      '& img': {
        width: '100%',
        objectFit: 'contain',
      },
    }}
  >
    <Coupon data={coupon} key={key} noTransformation />
  </Box>
)

const getSlides = (
  Slider,
  sliderProps,
  selectedPill,
  sliderFilterListTitle,
  blogCollection,
  slides = null,
  couponSliderSkus = [],
) => {
  const items = slides || Slider?.Slides || []

  return items
    .map((item, index) => {
      const getKey = generateItemKey(Slider, item)
      const View = {
        BaseCoupon: item?.Coupon && renderCouponTile(item?.Coupon, getKey('Coupon')),
        BaseCategoryTile:
          item?.CategoryTile &&
          renderCategoryTile(
            Slider,
            item,
            getKey('CategoryTile'),
            sliderProps,
            selectedPill,
            sliderFilterListTitle,
            index,
          ),
        BaseCollectionTile: item?.CollectionTile && (
          <CollectionTile data={item?.CollectionTile} key={getKey('CollectionTile')} blogCollection={blogCollection} />
        ),
        BaseProductTile:
          item?.SKU && renderProductTile(Slider, index, item?.SKU, getKey('SKU'), selectedPill, couponSliderSkus),
        BaseButton: item?.Button && <StrapiButton key={getKey('Button')} data={item?.Button} />,
        PageMarkdown: item?.Markdown && (
          <StrapiMarkdown key={getKey('Markdown')} data={item} gridWrapper={false} Alignment="center" />
        ),
      }
      return View[item.__typename] || null
    })
    .filter(component => Boolean(component))
}

export default getSlides
