import React from 'react'
import { urlToSearchState } from '@components/plp/helpers/state'

import { TitleText } from './CategoryTileCircle.styles'

export const capitalizeFirstLetter = str => {
  if (!str) return ''
  const lowerCaseStr = str.toLowerCase()
  const strArr = lowerCaseStr.split(' ')
  const strArrCapitalized = strArr.map(s => s.charAt(0).toUpperCase() + s.slice(1))
  return strArrCapitalized.join(' ')
}

export const generateSearchUrl = linkProps => {
  if (!linkProps?.data?.InternalUrl) return ''
  const link = linkProps.data.InternalUrl
  if (!link.includes('?')) return ''
  return `?${link.split('?')[1]}`
}

export const removeTrailingSlash = (str = '') => (str?.endsWith('/') ? str.slice(0, -1) : str)

// compares /furniture/living-rooms/chairs/recliners
export const isLinkToSamePage = (location, linkProps) => {
  if (!linkProps?.data?.InternalUrl) return false
  const pathname = removeTrailingSlash(location.pathname)
  const linkPathname = removeTrailingSlash(linkProps.data.InternalUrl.split('?')[0])
  return pathname === linkPathname
}

// /furniture/living-rooms/chairs/recliners?movement=power%20reclining&filters=true
export const isLinkToSamePageAndParams = (location, linkProps) => {
  if (!linkProps?.data?.InternalUrl) return false
  const relUrlLocation = removeTrailingSlash(location.pathname) + location.search
  const linkArr = linkProps.data.InternalUrl.split('?')
  const relUrlLink = `${removeTrailingSlash(linkArr[0])}?${linkArr[1]}`
  return relUrlLocation === relUrlLink
}

export const handleFilterTileClick = (e, location, linkProps, displayFilters, setStateFilters, isSelected) => {
  if (isSelected) e.preventDefault()
  else if (isLinkToSamePage(location, linkProps)) {
    e.preventDefault()
    const search = generateSearchUrl(linkProps)
    const filtersObj = urlToSearchState({ search }, displayFilters)
    setStateFilters(filtersObj.filters)
  }
}

export const getCategoryTileCircleHeader = (
  width,
  mobileWidth,
  isSelected,
  isActiveState,
  displayFilters,
  name,
  titleArray,
  shouldUseMinContent,
) => (
  <>
    {titleArray?.length === 1 && (
      <TitleText
        width={width}
        mobileWidth={mobileWidth}
        isSelected={isSelected}
        isActiveState={isActiveState}
        isDisplayFilter={displayFilters}
        name={name}
        shouldUseMinContent={shouldUseMinContent}
      >
        {titleArray[0]}
      </TitleText>
    )}
    {titleArray?.length > 1 && (
      <TitleText
        width={width}
        mobileWidth={mobileWidth}
        isSelected={isSelected}
        isActiveState={isActiveState}
        isDisplayFilter={displayFilters}
        name={name}
        style={{ marginTop: '0' }}
        shouldUseMinContent={shouldUseMinContent}
      >
        {titleArray[1]}
      </TitleText>
    )}
  </>
)
