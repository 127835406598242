import React, { useState } from 'react'
import { graphql } from 'gatsby'
import { object } from 'prop-types'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper-v9'
import { Swiper, SwiperSlide } from 'swiper-v9/react'
import 'swiper-v9/css'
import 'swiper-v9/css/navigation'
import 'swiper-v9/css/pagination'
import 'swiper-v9/css/scrollbar'
import { Box } from '@mui/material'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import StrapiBanner from '../Banner'
import {
  BannerSlideWrapper,
  CustomPaginationContainer,
  IconButtonStyled,
  PaginationSwiperWrapper,
} from './BannerSwiper.styles'

const BannerSwiper = ({ data }) => {
  const [slideIndex, setSlideIndex] = useState(0)

  const bannerSwiperData = data?.BannerSwiper || data

  const { BannersDesktop, BannersMobile, AutoScroll, DisplayDesktop, DisplayMobile, testId, Spacing, id } =
    bannerSwiperData ?? {}

  const handleSlideChange = currSwiper => {
    setSlideIndex(currSwiper.activeIndex)
  }

  const renderDesktopSwiper = () => {
    const isStart = slideIndex === 0
    const isEnd = BannersDesktop?.length === 0 || slideIndex === BannersDesktop?.length - 1

    return (
      <PaginationSwiperWrapper data-testid="component:desktop-banner-swiper" Spacing={Spacing}>
        <Swiper
          modules={[Navigation, Pagination, A11y, Autoplay]}
          onSlideChange={currSwiper => handleSlideChange(currSwiper)}
          pagination={{
            el: `.slider-button-${id}`,
            clickable: true,
          }}
          navigation={{
            nextEl: `.next-slide-${id}`,
            prevEl: `.prev-slide-${id}`,
          }}
          autoplay={{
            enabled: AutoScroll,
            delay: 5000,
            pauseOnMouseEnter: true,
            disableOnInteraction: true,
          }}
          speed={500}
          longSwipesRatio={0.12}
          a11y={{
            prevSlideMessage: 'Previous slide',
            nextSlideMessage: 'Next slide',
          }}
        >
          {BannersDesktop.map((banner, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <SwiperSlide key={`desktop-pagination-slide-${idx}`}>
              <BannerSlideWrapper>
                <StrapiBanner contentIndex={idx} data={banner} />
              </BannerSlideWrapper>
            </SwiperSlide>
          ))}
        </Swiper>
        <CustomPaginationContainer className="custom-pagination-container" id={id}>
          <IconButtonStyled
            className={`prev-slide-${id}`}
            disableRipple
            data-testid="component:desktop-banner-swiper-left-icon"
            disabled={isStart}
          >
            <ChevronLeftIcon htmlColor={isStart ? 'gray' : '#003566'} fontSize="medium" />
          </IconButtonStyled>
          <div className={`slider-button-${id}`} />
          <IconButtonStyled
            className={`next-slide-${id}`}
            disableRipple
            data-testid="component:desktop-banner-swiper-right-icon"
            disabled={isEnd}
          >
            <ChevronRightIcon htmlColor={isEnd ? 'gray' : '#003566'} fontSize="medium" />
          </IconButtonStyled>
        </CustomPaginationContainer>
      </PaginationSwiperWrapper>
    )
  }

  const renderMobileSwiper = () => (
    <PaginationSwiperWrapper data-testid="component:mobile-banner-swiper" Spacing={Spacing}>
      <Swiper
        modules={[Navigation, Scrollbar, A11y, Autoplay]}
        onSlideChange={currSwiper => handleSlideChange(currSwiper)}
        scrollbar={{
          display: true,
        }}
        autoplay={{
          enabled: AutoScroll,
          delay: 5000,
          pauseOnMouseEnter: true,
          disableOnInteraction: false,
        }}
        speed={400}
        longSwipesRatio={0.2}
        a11y={{
          prevSlideMessage: 'Previous slide',
          nextSlideMessage: 'Next slide',
        }}
      >
        {BannersMobile.map((banner, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <SwiperSlide key={`mobile-pagination-slide-${idx}`}>
            <BannerSlideWrapper>
              <StrapiBanner contentIndex={idx} data={banner} />
            </BannerSlideWrapper>
          </SwiperSlide>
        ))}
      </Swiper>
    </PaginationSwiperWrapper>
  )

  return (
    <>
      {DisplayDesktop && BannersDesktop && BannersDesktop.length !== 0 && (
        <Box sx={{ display: { xs: 'none', sm: 'block' }, zIndex: 0 }} data-testid={testId}>
          {renderDesktopSwiper()}
        </Box>
      )}
      {DisplayMobile && BannersMobile && BannersMobile.length !== 0 && (
        <Box sx={{ display: { sm: 'none', xs: 'block' }, zIndex: 0 }} data-testid={testId}>
          {renderMobileSwiper()}
        </Box>
      )}
    </>
  )
}

export default BannerSwiper

BannerSwiper.propTypes = {
  data: object,
}

export const StrapiPageCategoryFragment = graphql`
  fragment StrapiPageBannerSwiperFragment on StrapiBannerSwiper {
    testId
    id
    Title
    AutoScroll
    DisplayDesktop
    DisplayMobile
    BannersDesktop {
      Banner {
        ...StrapiPageBannerFragment
      }
    }
    BannersMobile {
      Banner {
        ...StrapiPageBannerFragment
      }
    }
    Spacing {
      TopPadding
      BottomPadding
    }
  }
`
