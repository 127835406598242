import PropTypes from 'prop-types'
import React, { useState, useEffect, useRef } from 'react'
import { styled, css } from '@mui/material'
import { breakPoints } from '@constants/styles'
import { titleCase, colorNameToHex, getContrastYIQ } from '@helpers/string-helper'
import useOutsideClick from '@helpers/hooks/useOutsideClick'
import { useTest } from '@hooks/useTest'
import Accordion, { summaryWithCounter } from './Accordion'
import FilterLink from './FilterLink'
import multiBackgroundImage from '../../../assets/images/plp-multi-swatch.png'

const FilterOuterWrapper = styled('div')`
  position: relative;
  overflow-wrap: normal;

  @media screen and (min-width: ${breakPoints['large-min']}) {
    & > :not(:first-child) {
      display: none;
    }
  }
`

const FilterDetailsWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${({ isColor }) =>
    isColor &&
    css`
      gap: 10px;
    `}
`

const FilterItemWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const FilterItemText = styled('span')`
  color: #333;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 20px;
  flex: 1;
`

const Checkbox = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  vertical-align: sub;
  border: 1px solid #585860;
  margin-right: 7px;
  width: 18px;
  height: 18px;
  border-radius: 3px;

  background-color: transparent;

  &:hover {
    border: 0.1rem solid #0053a0;
  }

  svg {
    display: none;
  }

  ${({ isApplied }) =>
    isApplied &&
    css`
      background: #0053a0;
      border: none;

      svg {
        display: block;
      }
    `}
`

const ColorBox = styled('div')`
  width: 24px;
  height: 24px;
  border: 1px solid #ccc;
  border-radius: 12px;
  margin-right: 8px;
  position: relative;
  display: grid;
  place-content: center;

  svg {
    display: none;
  }

  ${({ isApplied }) =>
    isApplied &&
    css`
      border-color: transparent;

      svg {
        display: block;
      }

      &::before {
        content: '';
        display: block;
        border: 1px solid #0053a0;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        left: -3px;
        height: calc(100% + 6px);
        width: calc(100% + 6px);
      }
    `}
`

const RefinementList = ({ items, attribute, applyStateChange, getUrl, infoButton }) => {
  const [amount, setAmount] = useState(0)
  const [isActive, setIsActive] = useState(false)
  const wrapperRef = useRef(null)
  const { generateTestId } = useTest()
  useOutsideClick(wrapperRef, () => setIsActive(false))

  useEffect(() => {
    const newAmount = items.reduce((prev, next) => (next?.isRefined ? prev + 1 : prev), 0)
    setAmount(newAmount)
  }, [items])

  const toggleFilter = forcedIsActive => {
    if (typeof forcedIsActive !== 'undefined') {
      setIsActive(forcedIsActive)
    }
    setIsActive(!isActive)
  }

  // TODO wut this do
  const splitters = [' ', ',', '-']
  const attributeLabel =
    !attribute.includes('size') && attribute.indexOf('_family') !== -1
      ? attribute.substring(0, attribute.indexOf('_family'))
      : titleCase(attribute.split('_').join(' '))

  if (items.length > 0) {
    return (
      <FilterOuterWrapper ref={wrapperRef}>
        <Accordion summary={summaryWithCounter(titleCase(attributeLabel, splitters), amount, infoButton)}>
          <FilterDetailsWrapper
            data-testid={generateTestId('refinement-list', `filter-details-wrapper-${attributeLabel}`)}
          >
            {items.map(
              item =>
                !item.label.includes(',') && (
                  <FilterLink
                    item={item}
                    attribute={attribute}
                    key={item.label}
                    applyStateChange={applyStateChange}
                    toggleFilter={toggleFilter}
                    getUrl={getUrl}
                  >
                    <FilterItemWrapper>
                      {attributeLabel !== 'color' ? (
                        <Checkbox isApplied={item.isRefined}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="16px"
                            viewBox="0 0 24 24"
                            width="16px"
                            fill="#fff"
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                          </svg>
                        </Checkbox>
                      ) : (
                        <ColorBox
                          isApplied={item.isRefined}
                          itemLabel={item.label}
                          sx={
                            item.label === 'multi'
                              ? {
                                  backgroundImage: `url(${multiBackgroundImage})`,
                                  backgroundSize: 'cover',
                                }
                              : { backgroundColor: colorNameToHex(item.label) }
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="18px"
                            viewBox="0 0 24 24"
                            width="18px"
                            fill={getContrastYIQ(colorNameToHex(item.label))}
                          >
                            <path d="M0 0h24v24H0z" fill="none" />
                            <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
                          </svg>
                        </ColorBox>
                      )}

                      <FilterItemText>{titleCase(item.label, splitters).replace(/,/g, ', ')}</FilterItemText>
                    </FilterItemWrapper>
                  </FilterLink>
                ),
            )}
          </FilterDetailsWrapper>
        </Accordion>
      </FilterOuterWrapper>
    )
  }
  return null
}

RefinementList.propTypes = {
  applyStateChange: PropTypes.any,
  attribute: PropTypes.any,
  getUrl: PropTypes.any,
  items: PropTypes.any,
  infoButton: PropTypes.shape({
    drawer: PropTypes.shape({
      open: PropTypes.bool,
      content: PropTypes.string,
    }),
    openDrawer: PropTypes.func,
    closeDrawer: PropTypes.func,
  }),
}

export default RefinementList
