import { isHero, isProductTile } from './helpers'

export const getBreakpoints = (Slider = null, sliderProps, blogCollection = false) => {
  if (!Slider) return defaultBreakpoints
  let breakpoints = defaultBreakpoints

  if (sliderProps?.displayFilters) breakpoints = categoryFilterBreakpoints
  else if (Slider?.MobileZoomView) breakpoints = mobileZoomBreakpoints
  else if (isHero(Slider)) breakpoints = heroBreakpoints(Slider)
  else if (isProductTile(Slider) && Slider?.NewProductTileView) breakpoints = newProductTileBreakpoints
  else if (isProductTile(Slider)) breakpoints = productTileBreakpoints
  else if (blogCollection) breakpoints = BlogCollectionTileBreakpoints
  else if (Slider?.NewSliderVariants) breakpoints = NewSliderVariantsBreakpoints(Slider.NewSliderVariants)
  return breakpoints
}

const categoryFilterBreakpoints = {
  0: {
    slidesPerView: 3.5,
  },
  680: {
    slidesPerView: 6,
  },
  1024: {
    slidesPerView: 6,
  },
}

export const heroBreakpoints = Slider => {
  const numOfSlides = Slider?.Slides?.length

  return {
    0: {
      slidesPerView: 1.4,
    },
    680: {
      slidesPerView: !numOfSlides || numOfSlides >= 4 ? 4 : numOfSlides,
    },
  }
}

export const defaultBreakpoints = {
  0: {
    slidesPerView: 1.4,
  },
  680: {
    slidesPerView: 4,
  },
}

export const productTileBreakpoints = {
  0: {
    slidesPerView: 'auto',
  },
  639: {
    slidesPerView: 3,
  },
  1280: {
    slidesPerView: 4,
  },
}

export const NewSliderVariantsBreakpoints = newSliderVariants => {
  let newSliderVariantsBreakpoints = {
    0: {
      slidesPerView: 1.4,
    },
    680: {
      slidesPerView: 4,
    },
  }
  if (newSliderVariants === 'ThreeDesktopTiles_OneMobileTile') {
    newSliderVariantsBreakpoints = {
      0: {
        slidesPerView: 1.4,
      },
      680: {
        slidesPerView: 3,
      },
    }
  }
  if (newSliderVariants === 'FiveDesktopTiles_TwoMobileTiles') {
    newSliderVariantsBreakpoints = {
      0: {
        slidesPerView: 2.4,
      },
      680: {
        slidesPerView: 5,
      },
    }
  } else if (newSliderVariants === 'SixDesktopTiles_TwoMobileTiles') {
    newSliderVariantsBreakpoints = {
      0: {
        slidesPerView: 2.4,
      },
      680: {
        slidesPerView: 6,
      },
    }
  }

  return newSliderVariantsBreakpoints
}

const mobileZoomBreakpoints = {
  0: {
    slidesPerView: 1.3,
  },
  680: {
    slidesPerView: 1.6,
  },
  1024: {
    slidesPerView: 2.4,
  },
  1250: {
    slidesPerView: 3,
  },
  1500: {
    slidesPerView: 4,
  },
}

const BlogCollectionTileBreakpoints = {
  0: {
    slidesPerView: 1.5,
  },
  678: {
    slidesPerView: 3,
  },
}

export const newProductTileBreakpoints = {
  0: {
    slidesPerView: 'auto',
    spaceBetween: 20,
  },
}
