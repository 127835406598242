import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { styled } from '@mui/material/styles'
import { useQuery, gql } from '@apollo/client'
import { getRegionZone } from '@helpers/geo-location'
import BaseSwiper from '../../../../@rtg2022/components/organisms/BaseSwiper/BaseSwiper'
import StrapiHeader from '../Header'
import getSlides from './getSlidesV2'
import { getBreakpoints } from './breakpoints'
import { isHero, getVisualFilterParams } from './helpers'

export const SliderWrapper = styled('div')(({ theme, spacing, displayDesktop, displayMobile }) => ({
  display: displayDesktop === false ? 'none' : 'block',
  padding: `${spacing?.TopPadding ?? 0}px 0 ${spacing?.BottomPadding ?? 0}px`,
  [theme.breakpoints.down('sm')]: {
    display: displayMobile === false ? 'none' : 'block',
  },
}))

const GET_PRODUCTS = gql`
  query PdpProduct(
    $skus: [String!]!
    $region: String!
    $priceZone: String = "0"
    $coupon: String
    $allowInStockSaleFlag: Boolean = true
    $locationState: String
    $distributionIndex: Int = 10
  ) {
    getProducts(
      skus: $skus
      region: $region
      distributionIndex: $distributionIndex
      priceZone: $priceZone
      coupon: $coupon
      allowInStockSaleFlag: $allowInStockSaleFlag
      locationState: $locationState
    ) {
      sku
      showAddToCart
      couponDisplayData {
        additionalDisclaimer
        title
        validThruMessage
        caption
      }
    }
  }
`

const StrapiSliderV2 = ({
  data,
  children = null,
  sliderProps = {},
  selectedPill = null,
  sliderFilterListTitle = null,
  blogCollection = false,
  isVisualFilter = false,
}) => {
  const Slider = data?.Slider || data

  const {
    selectedVisualFilterIndex,
    selectedVisualFilterIndexExists,
    noFadeoutForVisualFilterSelection,
  } = getVisualFilterParams(Slider, isVisualFilter)

  const { region, distribution_index, zone } = getRegionZone()

  const isProductTileSlider = Slider?.Slides?.[0]?.__typename === 'BaseProductTile'
  const productTileSkus = isProductTileSlider && Slider?.Slides?.map(slide => slide?.SKU)?.filter(sku => !!sku)
  const { data: productsData } = useQuery(GET_PRODUCTS, {
    variables: { skus: productTileSkus, region, priceZone: zone, distributionIndex: parseInt(distribution_index) },
    skip: !productTileSkus || productTileSkus.length < 1,
  })
  const getProducts = productsData?.getProducts

  const couponSliderSkus = useMemo(
    () => getProducts?.filter(product => product.couponDisplayData)?.map(product => product.sku),
    [getProducts],
  )

  const slides = useMemo(() => {
    let productSlides
    if (getProducts) {
      productSlides = getProducts
        ?.filter?.(product => product.showAddToCart)
        ?.map?.(product => ({
          SKU: product.sku,
          __typename: 'BaseProductTile',
        }))
    }

    return (
      children ||
      getSlides(
        Slider,
        sliderProps,
        selectedPill,
        sliderFilterListTitle,
        blogCollection,
        productSlides,
        couponSliderSkus,
      )
    )
  }, [
    getProducts,
    children,
    Slider,
    sliderProps,
    selectedPill,
    sliderFilterListTitle,
    blogCollection,
    couponSliderSkus,
  ])
  const isHeroBanner = useMemo(() => isHero(Slider), [Slider])
  const breakpoints = useMemo(() => sliderProps?.breakpoints ?? getBreakpoints(Slider, sliderProps, blogCollection), [
    sliderProps,
    Slider,
    blogCollection,
  ])
  const {
    ViewAllLink,
    Heading,
    DisplayHeader,
    DisplayDesktop,
    DisplayMobile,
    Spacing,
    DisplayScrollbar,
    testId,
    MobileZoomView,
    Header,
  } = Slider

  let headerData = Header

  if (!Header) {
    headerData = {
      Description: null,
      Heading,
      SeeAllLink: ViewAllLink,
      SubheadingMobile: 'link',
    }
  }
  const displayDesktop = DisplayDesktop !== false || sliderProps?.forceDisplayDesktopAndMobile === true
  const displayMobile = DisplayMobile !== false || sliderProps?.forceDisplayDesktopAndMobile === true
  const spacing = sliderProps?.forceNoSpacing ? null : Spacing

  return (
    <SliderWrapper spacing={spacing} displayDesktop={displayDesktop} displayMobile={displayMobile}>
      {(Header || DisplayHeader !== false) && !sliderProps?.forceHideTitle && <StrapiHeader data={headerData} />}
      <BaseSwiper
        title={DisplayHeader && !sliderProps?.forceHideTitle ? Heading : ''}
        slides={slides}
        spaceBetween={isHeroBanner ? 15 : sliderProps?.spaceBetweenDesktop ?? 32}
        spaceBetweenMobile={15}
        centered={sliderProps?.displayFilters || blogCollection}
        swiperStyles={{
          padding: sliderProps?.forceNoSpacing
            ? '0px'
            : `${Spacing?.TopPadding ?? 0}px 0 ${Spacing?.BottomPadding ?? 0}px`,
        }}
        headingStyles={{
          fontSize: '24px',
          marginBottom: '25px',
        }}
        breakpoints={breakpoints}
        hideScrollbar={
          DisplayScrollbar === false && !selectedVisualFilterIndexExists && !sliderProps.forceScrollbarDesktopAndMobile
        }
        fadeScrollbar={noFadeoutForVisualFilterSelection}
        fullWidthMobile={isHeroBanner || sliderProps?.fullWidthMobile || MobileZoomView}
        dataTestId={testId}
        mobileZoomView={MobileZoomView && !sliderFilterListTitle}
        isDisplayFilter={sliderProps?.displayFilters}
        activeIndex={isVisualFilter && selectedVisualFilterIndex}
        centerActiveSlide={isVisualFilter}
      />
    </SliderWrapper>
  )
}

StrapiSliderV2.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.element,
  sliderProps: PropTypes.object,
  selectedPill: PropTypes.string,
  sliderFilterListTitle: PropTypes.string,
  blogCollection: PropTypes.bool,
  isVisualFilter: PropTypes.bool,
}

export default StrapiSliderV2
