import { styled, IconButton } from '@mui/material'

export const BannerSlideWrapper = styled('div')(({ theme }) => ({
  '& > div': {
    margin: '0 !important',
  },
}))

export const CustomPaginationContainer = styled('div')(({ theme, id }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '10px',
  [`& .slider-button-${id}`]: {
    width: 'unset',
    display: 'flex',
    alignItems: 'center',
    margin: '0 16px',
  },
  '& .swiper-pagination-bullet': {
    color: theme.palette.primary.main,
    background: theme.palette.primary.main,
    opacity: 0.2,
    transition: 'opacity 0.2s, transform 0.2s',
    width: '8px',
    height: '8px',
    margin: '0 2px !important',
    '&.swiper-pagination-bullet-active': {
      opacity: 1,
      transform: 'scale(1.25)',
    },
  },
}))

export const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
}))

export const PaginationSwiperWrapper = styled('div')(({ theme, Spacing }) => ({
  padding: `${Spacing?.TopPadding ?? 0}px 0 ${Spacing?.BottomPadding ?? 0}px`,
  [theme.breakpoints.down('sm')]: {
    marginLeft: '-15px',
    marginRight: '-15px',
  },
  '& .swiper-wrapper': {
    paddingBottom: 0,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: '24px',
      marginBottom: '14px',
    },
  },
  '& .swiper-slide': {
    height: 'auto',
    width: '100%',
  },
  '& .swiper-scrollbar': {
    display: 'block',
    bottom: '18px',
    width: '90%',
    left: '5%',
    backgroundColor: `rgba(0, 83, 160, 0.08)`,
    '&-drag': {
      backgroundColor: `rgba(0, 83, 160, 0.5)`,
    },
  },
}))
