const algoliasearch = require('algoliasearch')
const algoliasearchHelper = require('algoliasearch-helper')
const { isEmpty } = require('lodash')

const getFilterString = (originalFilters, facetFilters) => {
  if (isEmpty(facetFilters)) {
    return originalFilters
  }
  let alteredFilters = originalFilters
  if (facetFilters && originalFilters.includes('"isShowcased":"true"')) {
    alteredFilters = originalFilters.replace('"isShowcased":"true"', '"isShowcased":"true" OR "isShowcased":"false"')
  }
  const additionalFilters = Object.keys(facetFilters)
    .map(
      facet =>
        `(${facetFilters[facet]
          .map(filter => `"${facet}":"${filter.replace(/\\/g, '\\\\').replace(/"/g, '\\"')}"`)
          .join(' OR ')})`,
    )
    .join(' AND ')
  return [alteredFilters, additionalFilters].filter(Boolean).join(' AND ')
}

exports.refine = ({ oldState, filters, page = 1, index = process.env.GATSBY_ALGOLIA_REGION_INDEX, plpBanners }) => {
  const client = algoliasearch(process.env.GATSBY_ALGOLIA_APP_ID, process.env.GATSBY_ALGOLIA_API_KEY)
  const hasUserFilter = !!Object.keys(filters ?? {}).length
  const searchSettings = {
    query: oldState?.query,
    facets: oldState?.facets,
    hitsPerPage: (oldState?.hitsPerPage || 21) - plpBanners,
    // TODO: removing this line should fix sorting, but it will break fetching
    ruleContexts: oldState?.ruleContexts,
    // ruleContexts: false, // to remove all pinned items from queries
    // enableRules: false, // whether rules should be globally enabled
    filters: getFilterString(oldState?.filters, filters),
    page: page - 1,
    distinct: hasUserFilter,
    ...(oldState?.attributesToRetrieve ? { attributesToRetrieve: oldState?.attributesToRetrieve } : {}),
  }

  return algoliasearchHelper(client, index, searchSettings).searchOnce()
}
