// dependecies
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import SlickSlider from '@rtgdev/react-slick'
import { makeStyles, Typography, Grid, Paper, useMediaQuery } from '@material-ui/core'
// styles
import '@comp-sass/shared/slider/slick.scss'
import '@comp-sass/shared/slider/slick-theme.scss'
// helpers
import { getWindowSizeSettings } from '@helpers/slider'
import useWindowResize from '@helpers/useWindowResize'
import { useQuery, gql } from '@apollo/client'
import { getRegionZone } from '@helpers/geo-location'
import getSlides from './getSlides'
// components
import SliderArrow from './SliderArrow'
import StrapiLink from '../Link'

const useStyles = makeStyles(theme => ({
  heading: {
    textAlign: 'center',
    position: 'relative',
    margin: '20px 0',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: '8px',
    },
  },
  linkWrapper: {
    position: 'absolute',
    top: '2rem',
    right: '6rem',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      position: 'unset',
      textAlign: 'center',
    },
  },
  link: {
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  headingTitle: {
    fontWeight: 300,
  },
  parentPaper: ({ styles }) => ({
    paddingTop: styles?.paddingTop || '1rem',
    paddingBottom: styles?.paddingBottom,
  }),
}))

const GET_PRODUCTS_V1 = gql`
  query PdpProduct(
    $skus: [String!]!
    $region: String!
    $priceZone: String = "0"
    $coupon: String
    $allowInStockSaleFlag: Boolean = true
    $locationState: String
    $distributionIndex: Int = 10
  ) {
    getProducts(
      skus: $skus
      region: $region
      distributionIndex: $distributionIndex
      priceZone: $priceZone
      coupon: $coupon
      allowInStockSaleFlag: $allowInStockSaleFlag
      locationState: $locationState
    ) {
      sku
      showAddToCart
    }
  }
`

const innerWidth = typeof window !== 'undefined' ? window.innerWidth : null

const StrapiSlider = ({ data, children = null, sliderProps = {} }) => {
  const { region, distribution_index, zone } = getRegionZone()
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const Slider = data?.Slider || data
  const className = Slider?.Size === 'Small' ? 'strapi__small-slider' : ''
  const paddings = Slider?.Spacing
  const styles = {
    paddingTop: paddings?.TopPadding >= 0 ? `${paddings?.TopPadding}px` : null,
    paddingBottom: paddings?.BottomPadding > 0 ? `${paddings?.BottomPadding}px` : null,
  }
  const classes = useStyles({ styles })
  const [windowWidth, setWindowWidth] = useState(innerWidth)

  const isProductTileSlider = Slider?.Slides?.[0]?.__typename === 'BaseProductTile'
  const productTileSkus = isProductTileSlider && Slider?.Slides?.map(slide => slide?.SKU)?.filter(sku => !!sku)
  const { data: productsData } = useQuery(GET_PRODUCTS_V1, {
    variables: { skus: productTileSkus, region, priceZone: zone, distributionIndex: parseInt(distribution_index) },
    skip: !productTileSkus || productTileSkus.length < 1,
  })
  const getProducts = productsData?.getProducts

  const slides = useMemo(() => {
    let productSlides
    if (getProducts) {
      productSlides = getProducts
        ?.filter?.(product => product.showAddToCart)
        ?.map?.(product => ({
          SKU: product.sku,
          __typename: 'BaseProductTile',
        }))
    }

    return children || getSlides(Slider, productSlides)
  }, [Slider, children, getProducts])

  const checkWindowWidth = useCallback(() => {
    if (typeof window !== 'undefined' && window.innerWidth !== windowWidth) {
      setWindowWidth(window.innerWidth)
    }
  }, [windowWidth])

  useWindowResize(checkWindowWidth, innerWidth)

  const settings = getWindowSizeSettings({
    ...sliderProps,
    nextArrow: <SliderArrow variant="next" heading={Slider?.Heading} />,
    prevArrow: <SliderArrow variant="previous" heading={Slider?.Heading} />,
    initialSlide: 0,
    infinite: false,
    windowWidth,
    sliderTitle: Slider?.Title || Slider?.Heading,
    label: Slider?.Title || Slider?.Heading,
  })

  useEffect(() => {
    document.querySelectorAll('.slick-list > span').forEach(el => {
      el.classList = 'hide508'
    })
  }, [])

  if ((isMobile && Slider.DisplayMobile === false) || (!isMobile && Slider.DisplayDesktop === false)) {
    return null
  }

  return (
    <Paper className={classes.parentPaper}>
      <Grid item md={12} className={className} data-testid={Slider?.testId || Slider?.id}>
        {(Slider?.Heading || Slider?.ViewAllLink?.Link) && (
          <div className={classes.heading}>
            {Slider?.Heading && (
              <Typography color="textPrimary" className={classes.headingTitle} variant="h5">
                {Slider.Heading.toUpperCase()}
              </Typography>
            )}
            {Slider?.ViewAllLink?.Link && (
              <div className={classes.linkWrapper}>
                <StrapiLink data={Slider?.ViewAllLink?.Link} arrowNext className={classes.link} />
              </div>
            )}
          </div>
        )}
        <SlickSlider {...settings}>{slides}</SlickSlider>
      </Grid>
    </Paper>
  )
}

StrapiSlider.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.element,
  sliderProps: PropTypes.object,
}

export default StrapiSlider
