import React from 'react'
import PropTypes from 'prop-types'
import { styled, css, Accordion, AccordionSummary, Typography, IconButton } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { colors, fonts } from '@constants/styles'
import ExpandSvg from '@components/shared/svgs/expandSvg'
import MovementOptionsDrawer from './MovementOptionsDrawer'

const StyledAccordion = styled(Accordion)(
  () => css`
    background: inherit;
    border: none;
    box-shadow: none;
    outline: none;
    width: 100%;

    border-bottom: 1px solid rgba(0, 0, 0, 0.12);

    &::before {
      display: none;
    }

    &:first-child,
    &:last-child {
      border-radius: 0;
    }

    &.Mui-expanded {
      margin: 0;
    }

    .MuiAccordionSummary-expandIcon {
      transform: rotate(-180deg);

      &.Mui-expanded {
        transform: none;
      }
    }

    .MuiAccordionSummary-root,
    .MuiAccordionSummary-root.Mui-expanded,
    .accordion-summary {
      min-height: 32px;
      padding: 10px 10px 9px;
    }

    .MuiAccordionSummary-content,
    .MuiAccordionSummary-content.Mui-expanded {
      margin: 0;
    }

    .MuiIconButton-root {
      padding: 4px;

      &.MuiIconButton-edgeEnd {
        margin-right: 0;
      }
    }

    .MuiCollapse-wrapper {
      padding: 6px 20px 24px;
    }

    .MuiAccordion-root.Mui-expanded {
      margin: 0;
    }
  `,
)

const ExpandIcon = styled(ExpandSvg)(
  () => css`
    fill: ${colors.primary};
  `,
)

const SummaryWrapper = styled('span')(
  () => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 22px;
  `,
)

const TextSummary = styled(Typography)(
  () => css`
    font-size: ${fonts.txtMedium};
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 22px;
    color: ${colors.navy};
    height: 22px;
    display: flex;
    gap: 5px;

    & > button {
      color: ${colors.navy};
    }
  `,
)

export function summaryWithCounter(title, number, infoButton) {
  const closeDrawer = e => {
    e.stopPropagation()
    infoButton.closeDrawer()
  }
  return (
    <SummaryWrapper>
      <TextSummary>
        {title}
        {infoButton && (
          <>
            <IconButton
              aria-label=""
              size="small"
              style={{ fontSize: '1rem', padding: '0' }}
              onClick={e => {
                e.stopPropagation()
                infoButton.openDrawer()
              }}
            >
              <InfoOutlinedIcon fontSize="inherit" />
            </IconButton>

            <MovementOptionsDrawer open={infoButton.drawer.open} onClose={closeDrawer} />
          </>
        )}
      </TextSummary>
    </SummaryWrapper>
  )
}

export function textSummary(title) {
  return <TextSummary>{title}</TextSummary>
}
export default function SimpleAccordion({
  className,
  summary,
  children,
  disableIcon,
  customIcon: CustomIcon,
  defaultExpanded = true,
  id,
}) {
  return (
    <StyledAccordion defaultExpanded={defaultExpanded} className={className} id={id}>
      <AccordionSummary
        className="accordion-summary"
        expandIcon={!disableIcon && (CustomIcon ? <CustomIcon /> : <ExpandIcon />)}
      >
        {summary}
      </AccordionSummary>
      <div>{children} </div>
    </StyledAccordion>
  )
}

SimpleAccordion.propTypes = {
  className: PropTypes.any,
  disableIcon: PropTypes.any,
  customIcon: PropTypes.any,
  summary: PropTypes.any,
  children: PropTypes.node,
  defaultExpanded: PropTypes.bool,
  id: PropTypes.string,
}
