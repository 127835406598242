import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useTest } from '@hooks/useTest'
import { useMediaQuery, useTheme } from '@mui/material'
import { useStrapiContext } from '@context/strapiContext'
import { getLinkProps } from '../Link'
import {
  TileWrapper,
  CircleWrapper,
  ImgStyle,
  StrapiLinkStyle,
  OnSaleWrapper,
  CheckCircleIconStyle,
  HeadingContainerStyle,
  HeadingBackgroundStyle,
} from './CategoryTileCircle.styles'
import {
  isLinkToSamePageAndParams,
  handleFilterTileClick,
  removeTrailingSlash,
  getCategoryTileCircleHeader,
} from './helpers'
import { splitName } from '../CategoryTile'

// Use Case 1: displayFilters exists.  Used inside PLP's SearchQuery content type, tapping into search results.
// Use Case 2: displayFilters = null.  Used inside TileGrid content type; serves as simple display and link.
const StrapiCategoryTileCircle = ({
  data,
  width = 'auto',
  mobileWidth = 'auto',
  displayFilters,
  setStateFilters,
  noBorder = false,
}) => {
  const { page } = useStrapiContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const titleArray = splitName(data?.Name, data?.MobileNameBreak, isMobile)
  const { generateTestId } = useTest()
  const link = data?.Link?.Link
  const windowLocationSearch = typeof window !== 'undefined' ? window.location?.search : null
  const isActiveState = removeTrailingSlash(page.Route) === link?.InternalUrl
  const ImageIncludesBackground = data?.ImageIncludesBackground

  const linkProps = useMemo(() => (link ? getLinkProps(data, link, { category: 'category tile' }) : {}), [data, link])

  const isSelected = useMemo(
    () => displayFilters && typeof window !== 'undefined' && isLinkToSamePageAndParams(window.location, linkProps),
    [displayFilters, linkProps, windowLocationSearch], // eslint-disable-line
  )

  const shouldUseMinContent = data?.Name?.length && data.Name.length > 12 && data.Name.includes(' ')

  const categoryTileCircleHeader = getCategoryTileCircleHeader(
    width,
    mobileWidth,
    isSelected,
    isActiveState,
    displayFilters,
    data?.Name,
    titleArray,
    shouldUseMinContent,
  )

  return (
    <StrapiLinkStyle
      data={{ ...link }}
      trackingData={{
        ...linkProps?.trackingData,
        category: `${displayFilters ? 'plp visual filter' : 'tile grid'} category tile`,
      }}
      disableUnderline
      onClick={e =>
        displayFilters
          ? handleFilterTileClick(e, window?.location, linkProps, displayFilters, setStateFilters, isSelected)
          : null
      }
    >
      <div data-testid={generateTestId('category-circle', 'tile')}>
        <TileWrapper data-testid={data?.testId}>
          {data?.OnSale && (
            <OnSaleWrapper>
              <div className="on-sale-flag">
                <p>SALE</p>
              </div>
              <div className="on-sale-flag-shadow" />
            </OnSaleWrapper>
          )}
          <CircleWrapper
            width={width}
            mobileWidth={mobileWidth}
            data-testid={isSelected ? 'category-circle-tile-is-selected' : 'category-circle-tile-is-not-selected'}
            ImageIncludesBackground={ImageIncludesBackground}
            noBorder={noBorder}
            isActiveState={isActiveState}
            isSelected={isSelected}
            border={isSelected || isActiveState ? `3px solid #0053a0` : '2px solid #BDBDBD'}
          >
            <ImgStyle
              data={{
                desktop: data?.Image,
                alt: data?.Name,
              }}
              initBgColor="transparent"
              ImageIncludesBackground={ImageIncludesBackground}
            />
          </CircleWrapper>
          {isActiveState ? (
            <HeadingContainerStyle>
              <HeadingBackgroundStyle shouldUseMinContent={shouldUseMinContent}>
                <CheckCircleIconStyle />
                {categoryTileCircleHeader}
              </HeadingBackgroundStyle>
            </HeadingContainerStyle>
          ) : (
            categoryTileCircleHeader
          )}
        </TileWrapper>
      </div>
    </StrapiLinkStyle>
  )
}

StrapiCategoryTileCircle.propTypes = {
  data: PropTypes.object.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayFilters: PropTypes.array,
  setStateFilters: PropTypes.func,
  noBorder: PropTypes.bool,
}

export default StrapiCategoryTileCircle
