export const generateImgData = (imageObj, mosaicTitle) => ({
  desktop: {
    url: `${imageObj?.url}${imageObj?.url?.includes('?') ? '&' : '?'}w=${imageObj.width}&h=${imageObj.height}`,
  },
  mobile: {
    url: `${imageObj?.url}${imageObj?.url?.includes('?') ? '&' : '?'}w=${imageObj.width * 2}&h=${imageObj.height * 2}`,
  },
  alt: imageObj?.alternativeText,
  title: mosaicTitle,
})

export const generateKey = (mosaicId, ItemRatio, isMobile, index) =>
  `${mosaicId}-${ItemRatio}-${index}-${isMobile ? 'mobile' : 'desktop'}`

export const getImageData = Image => ({
  url: Image?.url,
  alternativeText: Image?.alternativeText,
  width: Image?.width,
  height: Image?.height,
})

export const getRowHeight = (isMobile, itemRows) =>
  isMobile ? `calc(88vw * ${itemRows} / 12) !important` : `calc(38vw * ${itemRows} / 12) !important`

export const itemRatio = {
  TileA_Columns3_Rows8: { cols: 3, rows: 8, aspectRatio: '1/1.1' },
  TileB_Columns6_Rows9: { cols: 6, rows: 9, aspectRatio: '2.1/1.276' },
  TileC_Columns3_Rows5: { cols: 3, rows: 5, aspectRatio: '2.1/1.41' },
  TileD_Columns3_Rows7: { cols: 3, rows: 7, aspectRatio: '1.1/1.053' },
  TileE_Columns3_Rows6: { cols: 3, rows: 6, aspectRatio: '1/0.812' },
  TileF_Columns8_Rows8: { cols: 8, rows: 8, fullHeight: false, aspectRatio: '2/1.25' },
  TileG_Columns4_Rows8: { cols: 4, rows: 8, fullHeight: true },
  TileH_Columns4_Rows4: { cols: 4, rows: 4, fullHeight: false, aspectRatio: '2/1.24' },
  TileI_Columns_8_Rows3: { cols: 8, rows: 3, fullHeight: false, aspectRatio: '5/1.11' },
  TileJ_Columns8_Rows4: { cols: 8, rows: 4, fullHeight: true },
}

export const calculateTotalRows = (mosaicItems, displaySettings) => {
  const totalCols = displaySettings === 'Mobile' ? 8 : 12

  return mosaicItems.reduce(
    (acc, item) => {
      const tileKey = item.MosaicGridTile.TileColumnsAndRows
      const { cols, rows } = itemRatio[tileKey] || { cols: 0, rows: 0 }

      acc.accumulatedCols += cols
      acc.accumulatedRows += rows

      if (acc.maxRowsInCurrentSet === 0) {
        acc.maxRowsInCurrentSet = rows
      } else if (acc.maxRowsInCurrentSet !== rows) {
        acc.accumulatedTiles += 1
      }

      if (acc.accumulatedCols === totalCols) {
        if (acc.maxRowsInCurrentSet < acc.accumulatedRows && acc.accumulatedTiles > 1) {
          acc.accumulatedCols -= cols
          acc.accumulatedRows -= rows
          acc.accumulatedTiles -= 1
          return acc
        }
        acc.totalRows += acc.maxRowsInCurrentSet
        acc.accumulatedCols = 0
        acc.maxRowsInCurrentSet = 0
        acc.accumulatedRows = 0
        acc.accumulatedTiles = 0
      } else if (acc.accumulatedCols > totalCols) {
        acc.totalRows += acc.maxRowsInCurrentSet
        acc.accumulatedCols = cols
        acc.maxRowsInCurrentSet = rows
        acc.accumulatedRows = rows
        acc.accumulatedTiles = 1
      }

      return acc
    },
    { accumulatedCols: 0, totalRows: 0, maxRowsInCurrentSet: 0, accumulatedRows: 0, accumulatedTiles: 0 },
  ).totalRows
}
