import React from 'react'

import {
  Typography,
  useMediaQuery,
  Link,
  Box,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Container,
  styled,
  css,
} from '@mui/material'
import PropTypes from 'prop-types'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import Markdown from '@templates/strapi-cms/content-types/Markdown'
import StrapiImage from '@templates/strapi-cms/content-types/Image'

import SALE_FLAG from '../../atoms/SaleFlag/SaleFlag'

const generateImgData = (imageObj, width, height, rows, cols) => ({
  desktop: {
    url: `${imageObj?.url}${imageObj?.url?.includes('?') ? '&' : '?'}w=${width * cols}&h=${height * rows}`,
  },
  mobile: {
    url: `${imageObj?.url}${imageObj?.url?.includes('?') ? '&' : '?'}w=${width * cols}&h=${height * rows}`,
  },
  alt: imageObj.alternativeText,
  height: '100%',
  width: '100%',
})

const Wrapper = styled(Container)`
  width: 100%;
  padding-left: 0 !important;
  padding-right: 0 !important;
  max-width: none !important;

  ${p => p.theme.breakpoints.down('sm')} {
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    & .image-label {
      ${p => p.theme.typography.caption}
    }
  }
  ${({ margin }) =>
    css`
      margin-top: ${`${margin?.top}px` || 0};
      margin-bottom: ${`${margin?.bottom}px` || 0};
      margin-left: ${`${margin?.left}px` || 'auto'};
      margin-right: ${`${margin?.right}px` || 'auto'};
    `}
`

const Heading = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: ${p => p.theme.spacing(1)};

  h3,
  span,
  a {
    color: ${({ fontColor }) => fontColor || '#0c2240'};
    font-weight: 700;
    font-size: 1.375rem;
    letter-spacing: 0.15rem;
    text-transform: uppercase;
  }

  span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  svg {
    font-size: 1.8rem;
  }

  span,
  a {
    font-size: 1.5rem;
  }

  ${p => p.theme.breakpoints.down('md')} {
    svg {
      font-size: 1.5rem;
    }

    h3,
    span,
    a {
      font-size: 0.9rem;
    }
  }
`

const ImageItem = styled(ImageListItem)(
  ({ theme, isMarkdown, strapiAnimation, rowHeight, rows, itemShapeAndSize, isMobile }) => {
    const animation = strapiAnimation
      ? {
          '&:focus-visible': {
            outline: '4px solid',
            outlineOffset: '-4px',
            '& img': {
              transition: 'all 0.4s ease',
              opacity: '0.25',
            },
          },
          '&:hover': {
            '& img': {
              transform: 'scale(1.05)',
              transformOrigin: 'center',
            },
          },
        }
      : {}
    const transition = strapiAnimation ? { transition: 'all 1s ease' } : {}

    return {
      backgroundColor: isMarkdown ? '#E3E4E8' : theme.palette.common.white,
      overflow: 'hidden',
      display: isMarkdown ? 'flex' : 'block',
      alignItems: isMarkdown ? 'center' : null,
      height: itemShapeAndSize === 'RectangleVerticalMediumMobile' || (isMarkdown && isMobile) ? '100%' : rowHeight,
      maxHeight: `${100 * rows - (itemShapeAndSize === 'RectangleMedium' && !isMobile ? 7.5 : 0)}px`,

      '& a': {
        overflow: 'hidden',
        height: '100%',
        display: 'flex',
        ...animation,
      },
      '& img': {
        width: '100%',
        height: '100%',
        ...transition,
      },
    }
  },
)

export default function IMAGE_LIST({
  heading,
  fontColor,
  margin,
  itemData,
  strapiLink,
  strapiGap,
  strapiAnimation = true,
}) {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'))
  return (
    <Wrapper margin={margin}>
      <Heading fontColor={fontColor}>
        <Typography component="h3" mt={2}>
          {heading}
        </Typography>
        {strapiLink && (
          <span>
            {strapiLink}
            <NavigateNextIcon />
          </span>
        )}
      </Heading>
      <ImageList
        sx={{
          // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
          transform: 'translateZ(0)',
        }}
        gap={isMobile ? 7 : 15}
        cols={12}
        variant="quilted"
      >
        {itemData.map(item => {
          let cols = isMobile ? 6 : 3
          let rows = isMobile ? 6 : 3
          if (item?.itemShapeAndSize === 'SquareBig' || item?.featured) {
            cols = isMobile ? 12 : 6
            rows = isMobile ? 12 : 6
          } else if (item?.itemShapeAndSize === 'RectangleVerticalSmallMobile') {
            cols = 5
            rows = 6
          } else if (item?.itemShapeAndSize === 'RectangleVerticalMediumMobile') {
            cols = 6
            rows = 8
          } else if (item?.itemShapeAndSize === 'RectangleSmallMobile') {
            cols = 7
            rows = 6
          } else if (item?.itemShapeAndSize === 'RectangleBigDesktop5x6') {
            cols = isMobile ? 12 : 5
            rows = isMobile ? 12 : 6
          } else if (item?.itemShapeAndSize === 'RectangleBigDesktop') {
            cols = 7
            rows = 6
          } else if (item?.itemShapeAndSize === 'RectangleMedium') {
            cols = isMobile ? 12 : 5
            rows = isMobile ? 6 : 3
          }

          // TODO: Optimize images so that appropriate one is loaded based on grid size
          const product_image =
            isMobile ||
            item?.itemShapeAndSize === 'SquareSmall' ||
            item?.itemShapeAndSize === 'RectangleVerticalSmallMobile' ||
            item?.itemShapeAndSize === 'RectangleSmallMobile'
              ? item?.product_image?.small
              : item?.product_image?.large
          const itemMarkdown = item?.markdown?.Markdown?.Markdown?.data?.childMarkdownRemark?.html
          const itemMarkdownMobile = item?.markdown?.Markdown?.MarkdownMobile?.data?.childMarkdownRemark?.html
          const isMarkdown = (!isMobile && itemMarkdown) || (isMobile && (itemMarkdown || itemMarkdownMobile))
          const imageData = {
            url: product_image,
            alternativeText: item?.alternativeText,
          }

          return (
            <ImageItem
              key={item?.id}
              cols={cols}
              rows={rows}
              isMarkdown={isMarkdown}
              strapiAnimation={strapiAnimation}
              rowHeight={`calc((80vw * (${rows} / 12) ${
                item?.itemShapeAndSize === 'RectangleMedium' && !isMobile ? '- 7.5px' : ''
              })) !important`}
              isMobile={isMobile}
              itemShapeAndSize={item?.itemShapeAndSize}
            >
              {item.sale && <SALE_FLAG label="SALE" />}
              {isMarkdown ? (
                <Markdown
                  data={{
                    Markdown: item?.markdown.Markdown.Markdown,
                    MarkdownMobile: item?.markdown?.Markdown?.MarkdownMobile,
                    PaddingTop: 0,
                    PaddingBottom: 0,
                    PaddingLeft: 0,
                    PaddingRight: 0,
                  }}
                />
              ) : (
                <Link href={item?.route} sx={{ position: 'relative' }}>
                  <StrapiImage
                    data={generateImgData(imageData, isMobile ? 70 : 250, isMobile ? 58 : 210, rows, cols)}
                    noPaddingBottom
                    objectFit
                  />
                  {item?.showDisplayName !== false && (
                    <ImageListItemBar
                      sx={{
                        height: '50%',
                        alignItems: 'end',
                        background: item?.gradient
                          ? 'linear-gradient(5deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0) 78%)'
                          : 'transparent',
                      }}
                      title={
                        <Typography
                          color="common.white"
                          variant="h2"
                          component="div"
                          textTransform="uppercase"
                          whiteSpace="nowrap"
                          textOverflow="ellipsis"
                          overflow="hidden"
                          className="image-label"
                        >
                          {item?.product_label}
                        </Typography>
                      }
                      // subtitle="Subtitle placeholder"
                      position="bottom" // top, bottom, below
                    />
                  )}
                </Link>
              )}
            </ImageItem>
          )
        })}
      </ImageList>
    </Wrapper>
  )
}

IMAGE_LIST.propTypes = {
  heading: PropTypes.string.isRequired,
  fontColor: PropTypes.string,
  margin: PropTypes.shape({
    top: PropTypes.string,
    bottom: PropTypes.string,
    left: PropTypes.string,
    right: PropTypes.string,
  }),
  strapiLink: PropTypes.object.isRequired,
  itemData: PropTypes.array.isRequired,
  strapiGap: PropTypes.string,
  strapiAnimation: PropTypes.bool,
}
