import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'
import { styled } from '@mui/material'
import { Grid } from '@material-ui/core'
import CloseIcon from '@mui/icons-material/Close'
import { breakPoints } from '@constants/styles'
import SearchFilters from './search-filters'
import SearchSorting from './search-sorting'
import Stats from './plp-parts/Stats'

const Summary = styled(Grid)`
  &&& {
    background-color: rgba(0, 83, 160, 0.04);
    padding: 10px;
    margin-bottom: 20px;
    display: none;
    @media only screen and (min-width: ${breakPoints['large-min']}) {
      display: flex;
    }
  }
`

const SearchMobileTitle = styled('span')`
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  color: #333;
  margin-top: 12px;
  margin-left: 10px;
`

const MobileHeaderWrapper = styled(Grid)`
  &&& {
    margin-bottom: 24px;
  }
`

const CloseButton = styled('button')`
  display: grid;
  place-content: center;
  align-self: flex-start;
  color: #333;
`

const Sidebar = ({
  searching,
  searchState,
  applyStateChange,
  refinementList,
  matchPath,
  refinedSearchResults,
  getUrl,
  stats,
  clearSortBy,
  setOpenModalSidebar,
  openModalSidebar,
}) => {
  const isMobile = useSelector(state => state.global.isMobile)

  const [open, setOpen] = React.useState(false)

  const header = (
    <MobileHeaderWrapper container justifyContent="space-between" alignItems="flex-end">
      <SearchMobileTitle>Sort &amp; Filter</SearchMobileTitle>
      <CloseButton
        type="button"
        title="Clear the search query."
        hidden=""
        onClick={() => setOpenModalSidebar(false)}
        tabIndex={0}
      >
        <CloseIcon />
      </CloseButton>
    </MobileHeaderWrapper>
  )

  const body = (
    <>
      <Summary container justifyContent="space-between" alignItems="center">
        <Stats
          nbHits={refinedSearchResults?.content?.hits?.length && refinedSearchResults?.content?.nbHits}
          searching={searching}
          cbFunction={clearSortBy}
        />
      </Summary>
      <SearchSorting sortBy={searchState?.sortBy} applyStateChange={applyStateChange} />
      <SearchFilters
        attributes={refinementList}
        matchPath={matchPath}
        facets={refinedSearchResults?.content?.facets}
        applyStateChange={applyStateChange}
        getUrl={getUrl}
        statsComponent={stats}
      />
    </>
  )

  if (isMobile && openModalSidebar) {
    return (
      <>
        {header}
        {body}
      </>
    )
  }

  if (isMobile && !openModalSidebar) {
    return null
  }

  return body
}

Sidebar.propTypes = {
  searching: PropTypes.any,
  searchState: PropTypes.any,
  applyStateChange: PropTypes.any,
  refinementList: PropTypes.any,
  matchPath: PropTypes.any,
  refinedSearchResults: PropTypes.any,
  getUrl: PropTypes.any,
  stats: PropTypes.any,
  clearSortBy: PropTypes.func,
  setOpenModalSidebar: PropTypes.func,
  openModalSidebar: PropTypes.bool,
}

export default Sidebar
