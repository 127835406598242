import { useState, useEffect, useRef } from 'react'

// This function determines if there are some tiles overflowing and not visible within the Grid or TileGrid
export const checkOverflow = args => {
  const {
    gridContainerRef,
    isOverflowRef,
    isExpandedRef,
    setIsExpanded,
    setIsOverflow,
    isCollapsable,
    isFirstExpansion,
  } = args

  const isOverflowing = gridContainerRef.current.scrollHeight > gridContainerRef.current.clientHeight

  if ((isOverflowing && !isOverflowRef.current) || (!isOverflowing && isOverflowRef.current)) {
    setIsOverflow(isOverflowing)
  }

  if (!isOverflowing && !isExpandedRef.current) {
    if (!isFirstExpansion) setIsExpanded(true)
  }
  if (isCollapsable === false) {
    setIsExpanded(true)
  }
}

// checks gridContainer's parent to determine if grid is currently displayed
export const isDisplayed = gridContainerRef => {
  if (gridContainerRef.current) {
    const { parentElement } = gridContainerRef.current
    return parentElement && window.getComputedStyle(parentElement).display !== 'none'
  }
  return false
}

// This function determines the number of rows currently visible in the Grid or TileGrid
export const checkRenderedRows = (gridContainerRef, renderedRowsRef, setRenderedRows) => {
  const gridHeight = gridContainerRef.current.offsetHeight
  const itemHeight = gridContainerRef.current.children[0]?.offsetHeight

  if (itemHeight) {
    const numRows = Math.floor(gridHeight / itemHeight)
    if (renderedRowsRef.current !== numRows) {
      setRenderedRows(numRows)
    }
  }
}

export default ({ numOfRowsCollapsed, isExpanded, _setIsExpanded, isCollapsable }) => {
  const [isOverflow, _setIsOverflow] = useState(null)
  const [renderedRows, _setRenderedRows] = useState(2)
  const [isFirstExpansion, setIsFirstExpansion] = useState(true)
  const gridContainerRef = useRef(null)

  // creating refs to provide current state data within the event listener
  const isExpandedRef = useRef(isExpanded)
  const setIsExpanded = value => {
    isExpandedRef.current = value
    _setIsExpanded(value)
  }

  const isOverflowRef = useRef(isOverflow)
  const setIsOverflow = value => {
    isOverflowRef.current = value
    _setIsOverflow(value)
  }

  const renderedRowsRef = useRef(renderedRows)
  const setRenderedRows = value => {
    renderedRowsRef.current = value
    _setRenderedRows(value)
  }

  useEffect(() => {
    window.addEventListener('resize', checkOverflowAndRenderedRows)
    checkOverflowAndRenderedRows()
    return () => {
      window.removeEventListener('resize', checkOverflowAndRenderedRows)
    }
    // eslint-disable-next-line
  }, [])

  // After user clicks See More or See Less, checks need to occur after a rerender
  useEffect(() => {
    checkOverflowAndRenderedRows()
    // eslint-disable-next-line
  }, [isExpanded])

  const checkOverflowAndRenderedRows = () => {
    if (isDisplayed(gridContainerRef)) {
      checkOverflow({
        gridContainerRef,
        isOverflowRef,
        isExpandedRef,
        setIsExpanded,
        setIsOverflow,
        isCollapsable,
        isFirstExpansion,
      })
      checkRenderedRows(gridContainerRef, renderedRowsRef, setRenderedRows)
    }
  }

  const handleSeeMoreClick = e => {
    e.preventDefault()
    setIsExpanded(!isExpanded)
    if (isFirstExpansion) {
      setIsFirstExpansion(false)
    }
  }

  const isSeeMore = !isExpanded && isOverflow
  const isSeeLess = isExpanded && renderedRows > numOfRowsCollapsed

  return {
    gridContainerRef,
    handleSeeMoreClick,
    isExpanded,
    isSeeMore,
    isSeeLess,
  }
}
