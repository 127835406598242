import { styled } from '@mui/material/styles'
import { Link as MuiLink } from '@mui/material'

export const Container = styled('div')(({ theme, Spacing, DisplayDesktop, DisplayMobile }) => ({
  display: DisplayDesktop === false ? 'none' : 'block',
  paddingTop: Spacing?.TopPadding ? `${Spacing.TopPadding}px` : 0,
  paddingBottom: Spacing?.BottomPadding ? `${Spacing.BottomPadding}px` : 0,
  [theme.breakpoints.down('md')]: {
    display: DisplayMobile === false ? 'none' : 'block',
  },
}))

export const DesktopTileGridWrapper = styled('div')(
  ({ tileWidthDesktop, isExpandedDesktop, spaceBetweenDesktop = 60, collapsedRows }) => ({
    display: 'grid',
    gridTemplateRows: `repeat(${collapsedRows}, 1fr)`,
    gridTemplateColumns: `repeat(auto-fit, ${tileWidthDesktop}px)`,
    gridAutoRows: isExpandedDesktop ? 'auto' : 0,
    overflowY: isExpandedDesktop ? 'visible' : 'hidden',
    columnGap: `${spaceBetweenDesktop}px`,
    '& > *': {
      marginBottom: '35px',
    },
    justifyContent: 'center',
  }),
)
export const IpadTileGridWrapper = styled('div')(
  ({ spaceBetweenIpad, numOfColumns, isExpandedIpad, collapsedRows }) => ({
    display: 'grid',
    gridTemplateRows: `repeat(${collapsedRows}, 1fr)`,
    gridTemplateColumns: `repeat(${numOfColumns}, 1fr)`,
    gridAutoRows: isExpandedIpad ? 'auto' : 0,
    overflowY: isExpandedIpad ? 'visible' : 'hidden',
    columnGap: `${spaceBetweenIpad}px`,
    '& > *': {
      marginBottom: '35px',
    },
    justifyContent: 'center',
  }),
)

export const MobileTileGridWrapper = styled('div')(({ theme, numOfColumns, isExpandedMobile, collapsedRows }) => ({
  display: 'grid',
  gridTemplateRows: `repeat(${collapsedRows}, 1fr)`,
  gridAutoRows: isExpandedMobile ? 'auto' : 0,
  overflowY: isExpandedMobile ? 'visible' : 'hidden',
  [theme.breakpoints.down('sm')]: {
    gridTemplateColumns: `repeat(${numOfColumns}, 1fr)`,
    columnGap: '20px',
    '& > *': {
      marginBottom: '25px',
    },
  },
  [theme.breakpoints.down('xs')]: {
    columnGap: '10px',
    '& > *': {
      marginBottom: '25px',
    },
  },
}))

export const SeeMoreWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '10px',
}))

export const SeeMoreButton = styled(MuiLink)(({ theme }) => ({
  fontSize: '16px',
  minHeight: '21px',
  lineHeight: '21px',
}))
