// dependencies
import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'
import { isEmpty } from 'lodash'
import { Typography } from '@material-ui/core'
// helpers
import { productUrl } from '@helpers/route'
// components
import SearchResults from '@components/plp/search-results'
// context
import { useStrapiContext } from '@context/strapiContext'

const DISPLAY_FILTERS = [
  'age',
  'brand',
  'color_family',
  'comfort',
  'decor',
  'feature',
  'gender',
  'height',
  'material_family',
  'piece count',
  'size',
  'size_family',
  'style',
  'technology',
]

const getMetaLinks = (hasFilters = false, page = {}) => {
  const links = [
    {
      rel: 'canonical',
      href: typeof window !== 'undefined' ? window.location.href : `https://www.roomstogo.com${page?.Route}`,
    },
  ]
  if (hasFilters) {
    return [
      ...links,
      {
        rel: 'canonical',
        href: typeof window !== 'undefined' ? window.location.href : `https://www.roomstogo.com${page?.Route}`,
      },
    ]
  }
  return links
}

const StrapiSearchQuery = ({ data }) => {
  const { page } = useStrapiContext()
  const [hasFilters, setHasFilters] = useState(false)

  const results = data?.SearchQuery || {}
  const displayFilters = results?.Query?.displayFilters || DISPLAY_FILTERS
  const visualFilterData = results?.VisualFilter

  const searchResponse = useMemo(() => (results?.fields ? JSON.parse(results?.fields.response) : null), [results])

  const hits = searchResponse?.content?.hits

  const onFiltersChange = useCallback(
    filters => {
      const containsFilters = !isEmpty(filters)
      if (hasFilters !== containsFilters) {
        setHasFilters(containsFilters)
      }
    },
    [hasFilters],
  )

  if (!searchResponse) {
    return (
      <div data-testid={results?.testId} className="search-query">
        <Typography variant="subtitle1">No data found...</Typography>
      </div>
    )
  }

  // * IF there is a PLP with only one product, Reroute directly to PDP instead.
  if (typeof window !== 'undefined' && Array.isArray(hits) && hits.length === 1) {
    const product = hits[0]
    // For pdp navigation we cannot do client side nav so using window.location instead of gatsby navigate
    window.location.replace(productUrl(product?.route))
    return null
  }

  return (
    <div data-testid={results?.testId} className="search-query">
      <Helmet link={getMetaLinks(hasFilters, page)} />
      <SearchResults
        displayFilters={displayFilters}
        matchPath={page?.matchPath?.replace(/\*/g, '') || ''}
        productType={results.ProductType}
        source="plp"
        queryData={results}
        plpBanners={results?.PlpBanners.map(plpBanner => plpBanner?.Banner)}
        searchResults={searchResponse}
        onFiltersChange={onFiltersChange}
        visualFilterData={visualFilterData}
      />
    </div>
  )
}

StrapiSearchQuery.propTypes = {
  data: PropTypes.shape({
    SearchQuery: PropTypes.object.isRequired,
  }),
}

export default StrapiSearchQuery

export const SearchQueryStrapiFragment = graphql`
  fragment SearchQueryStrapiFragment on StrapiSearchQuery {
    id
    testId
    Title
    RuleContext
    ProductType
    fields {
      response
    }
    Query {
      id
      region
      keywords
      hitsPerPage
      filters
      displayFilters
      ruleContexts
    }
    PlpBanners {
      Banner {
        TileIndex
        Banner {
          Banner {
            ...StrapiPageBannerFragment
          }
        }
      }
    }
    VisualFilter {
      Slider {
        ...StrapiSliderfragment
      }
    }
  }
`
