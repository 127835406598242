import React from 'react'
import RTGLink from '@shared/link'
import rtgTo from '@helpers/rtg-to'
import { useTest } from '@hooks/useTest'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const FilterLink = ({ item, attribute, children, applyStateChange, toggleFilter, getUrl }) => {
  const isMobile = useSelector(state => state.global.isMobile)
  const link = getUrl(attribute, item.label)
  const { generateTestId } = useTest()

  return (
    <RTGLink
      data={{
        rel: 'nofollow',
        [rtgTo(link)]: link,
        category: 'plp parts',
        action: 'filter link click',
        label: 'filter link',
      }}
      onKey={e => {
        if (e.keyCode !== 32) return
        // spacebar press
        if (e.keyCode === 32) {
          e.preventDefault()
          e.target.click()
        }
      }}
      onClick={e => {
        e.preventDefault()
        applyStateChange(attribute, item.label)
        if (!isMobile) toggleFilter()
        const productResultsWrapper = document.querySelector('#productResultsWrapper')
        if (productResultsWrapper) {
          productResultsWrapper.focus()
          window.scrollTo({ top: 0 })
        }
      }}
      data-testid={generateTestId('refinement-list', `filter-link-${item.label}`)}
    >
      {children}
    </RTGLink>
  )
}

FilterLink.propTypes = {
  applyStateChange: PropTypes.func,
  attribute: PropTypes.any,
  children: PropTypes.any,
  getUrl: PropTypes.func,
  item: PropTypes.any,
  toggleFilter: PropTypes.func,
}

export default FilterLink
