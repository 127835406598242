/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import urlJoin from 'url-join'
import { Card, CardContent, Drawer, Typography, useMediaQuery, useTheme, Box } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { isValidUrl } from '@helpers/general'
import storageConsoleImg from '../../../assets/images/modular-one-storage-console.png'

const MovementOptionsDrawer = ({ open, onClose }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
  const videoURL = urlJoin(process.env.GATSBY_S3_IMAGE_URL, 'video')

  const OPTION_LABELS = ['non-power', 'single power', 'dual power', 'triple power', 'rocker', 'swivel']
  const ROW_LABELS = {
    'manual control': ['non-power'],
    'power adjustable recliner': ['single power', 'dual power', 'triple power'],
    'power adjustable headset': ['dual power', 'triple power'],
    'power adjustable lumbar': ['dual power', 'triple power'],
    'power adjustable lumbar support': ['triple power'],
    'rock forward & backward': ['rocker'],
    '360 rotation': ['swivel'],
  }

  const [selectedOption, setSelectedOption] = useState(OPTION_LABELS[0])

  const toUrl = label => {
    switch (label) {
      case 'non-power':
        return urlJoin(videoURL, 'manual-power-recliner.mp4')
      case 'single power':
        return urlJoin(videoURL, 'single-power-recliner.mp4')
      case 'dual power':
        return urlJoin(videoURL, 'dual-power-recliner.mp4')
      case 'triple power':
        return urlJoin(videoURL, 'triple-power-recliner.mp4')
      case 'media console':
        return urlJoin(videoURL, 'RTG_M3_FINAL_nologos_071323.mp4')
      case 'swivel':
        return urlJoin(videoURL, 'swivel-anim.mp4')
      case 'rocker':
        return urlJoin(videoURL, 'rocker-anim.mp4')
      default:
        return label
    }
  }

  const ReclinerVideo = ({ label }) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        aspectRatio: '550 / 367',
        '& video': {
          display: 'block',
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        },
        '& img': {
          display: 'block',
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        },
      }}
    >
      {isValidUrl(toUrl(label)) ? (
        <video autoPlay data-testid="recliner-video">
          <source src={toUrl(label).toString()} />
        </video>
      ) : (
        <img src={storageConsoleImg} alt="Storage Console" />
      )}
    </Box>
  )

  return (
    <Drawer
      open={open}
      onClick={e => {
        e.stopPropagation()
      }}
      onClose={onClose}
      anchor="right"
      sx={{ zIndex: '1300 !important' }}
    >
      <Card
        sx={{
          display: 'flex',
          width: '100vw',
          maxWidth: 730,
          minWidth: isMobile ? '100vw' : '100%',
          minHeight: isMobile ? '100vh' : '100%',
          justifyContent: 'center',
        }}
        square
      >
        <CardContent sx={{ boxSizing: 'border-box', padding: 0, width: '100%', overflow: 'auto' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '20px 15px 15px 15px',
              position: 'relative',
            }}
          >
            <Typography
              color="primary.main"
              sx={{
                fontSize: '23px',
                lineHeight: '31.12px',
                fontWeight: 500,
                textTransform: 'uppercase',
              }}
            >
              Reclining Options
            </Typography>
            <Box
              component="button"
              onClick={onClose}
              sx={{
                width: 48,
                height: 48,
                position: 'absolute',
                right: 5,
                top: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '50%',
              }}
            >
              <CloseOutlinedIcon sx={{ color: '#808080', fontSize: 24 }} />
            </Box>
          </Box>
          <ReclinerVideo label={selectedOption} />
          <Box sx={{ display: 'flex', paddingX: '15px', gap: '8px', paddingTop: '14px' }}>
            <Box sx={{ display: 'grid', minWidth: '98px', width: '98px', paddingBottom: '48px' }}>
              <Box sx={{ height: '46px' }} />
              {Object.keys(ROW_LABELS).map(label => (
                <Box
                  key={label}
                  sx={{
                    height: '46px',
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    color: '#003566',
                    fontWeight: 400,
                    lineHeight: '15.84px',
                    textAlign: 'left',
                    fontSize: '12px',
                    textTransform: 'capitalize',
                  }}
                >
                  {label}
                </Box>
              ))}
            </Box>
            <Box
              sx={{
                overflow: 'auto',
                display: 'grid',
                flex: 1,
                paddingBottom: '48px',
                '&::-webkit-scrollbar': {
                  height: '3px',
                },
                '&::-webkit-scrollbar-track': {
                  background: '#0053A014',
                  borderRadius: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  background: '#0053A080',
                  borderRadius: '6px',
                },
              }}
            >
              <Box
                sx={{
                  display: 'grid',
                  gridAutoFlow: 'column',
                  gridAutoColumns: 'minmax(110px, 1fr)',
                  gap: '8px',
                  minHeight: '46px',
                  width: '100%',
                }}
              >
                {OPTION_LABELS.map(option => (
                  <Box
                    key={option}
                    sx={{
                      alignItems: 'center',
                      display: 'flex',
                      justifyContent: 'center',
                      textAlign: 'center',
                    }}
                  >
                    <Box
                      component="button"
                      onClick={() => setSelectedOption(option)}
                      sx={{
                        backgroundColor: selectedOption === option ? '#0053A01F' : '#0053A00A',
                        color: '#003566',
                        fontWeight: selectedOption === option ? 600 : 500,
                        lineHeight: selectedOption === option ? '19.4px' : '18.94px',
                        width: '100%',
                        height: '100%',
                        whiteSpace: 'nowrap',
                        borderRadius: '4px',
                        textAlign: 'center',
                        fontSize: '14px',
                        textTransform: 'uppercase',
                        '&:focus': {
                          outline: 'none',
                        },
                        '&:focus-visible': {
                          outline: 'auto',
                        },
                      }}
                    >
                      {option}
                    </Box>
                  </Box>
                ))}
              </Box>
              {Object.entries(ROW_LABELS).map(([label, options]) => (
                <Box
                  key={label}
                  sx={{
                    display: 'grid',
                    gridAutoFlow: 'column',
                    gridAutoColumns: 'minmax(110px, 1fr)',
                    gap: '8px',
                    minHeight: '46px',
                    width: '100%',
                    borderBottom: '1px solid #0000001F',
                  }}
                >
                  {OPTION_LABELS.map(option => (
                    <Box
                      key={option}
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        color: '#333',
                        fontWeight: 500,
                        lineHeight: '25.71px',
                        textAlign: 'center',
                        fontSize: '19px',
                      }}
                    >
                      {options.includes(option) ? <CheckOutlinedIcon sx={{ color: '#00854A', fontSize: 24 }} /> : '-'}
                    </Box>
                  ))}
                </Box>
              ))}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Drawer>
  )
}
MovementOptionsDrawer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
}

export default MovementOptionsDrawer
