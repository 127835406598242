import { useEffect } from 'react'

const useScrollToFragment = () => {
  useEffect(() => {
    const scrollToSection = () => {
      if (window.location.hash) {
        const fragment = window.location.hash
        const divArray = document.querySelectorAll(fragment)
        const targetSection = window.innerWidth > 639 ? divArray[0] : divArray[1]
        if (!targetSection) return
        targetSection.tabIndex = 0
        targetSection.focus()
        const targetPosition = targetSection.getBoundingClientRect().top + window.scrollY
        window.scrollTo({ top: targetPosition })
      }
    }

    setTimeout(scrollToSection(), 0)
  }, [])
}

export default useScrollToFragment
