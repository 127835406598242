// dependecies
import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import StrapiSlider from './Slider'
import StrapiSliderV2 from './Slider-v2'

const StrapiSliderWrapper = ({ data, children = null, sliderProps = {} }) => {
  const Slider = data?.Slider || data

  if (Slider?.NewSliderView || Slider?.CategoryFilterView || Slider?.NewProductTileView || Slider?.MobileZoomView) {
    return <StrapiSliderV2 data={data} sliderProps={sliderProps} />
  }

  return (
    <StrapiSlider data={data} sliderProps={sliderProps}>
      {children}
    </StrapiSlider>
  )
}

StrapiSliderWrapper.propTypes = {
  data: PropTypes.object.isRequired,
  children: PropTypes.element,
  sliderProps: PropTypes.object,
}

export default StrapiSliderWrapper

export const StrapiSliderFragment = graphql`
  fragment StrapiSliderfragment on StrapiSlider {
    id
    testId
    Title
    Heading
    DisplayHeader
    DisplayScrollbar
    Size
    NewCategoryTileView
    NewSliderView
    NewProductTileView
    ViewAllLink {
      Link {
        ...StrapiLinkFragment
      }
    }
    Slides {
      __typename
      ... on BaseProductTile {
        SKU
      }
      ... on BaseCollectionTile {
        CollectionTile {
          ...StrapiCollectionTileFragment
        }
      }
      ... on BaseCategoryTile {
        CategoryTile {
          ...StrapiCategoryTileFragment
        }
      }
      ... on BaseCoupon {
        Coupon {
          ...StrapiCouponFragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on BaseButton {
        Button {
          ...StrapiButtonFragment
        }
      }
    }
    Spacing {
      TopPadding
      BottomPadding
    }
    DisplayDesktop
    DisplayMobile
    NewSliderVariants
    MobileZoomView
    Header {
      ...StrapiHeaderFragment
    }
  }
`
