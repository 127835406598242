import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box } from '@mui/material'
import { useTest } from '@hooks/useTest'
import HeadingAndSeeAllLink from '../../../../@rtg2022/components/molecules/HeadingAndSeeAllLink/HeadingAndSeeAllLink'
import getTiles, { calculateTileWidth } from './getTiles'
import {
  Container,
  DesktopTileGridWrapper,
  IpadTileGridWrapper,
  MobileTileGridWrapper,
  SeeMoreWrapper,
  SeeMoreButton,
} from './index.styles'
import useSeeMore from '../../../../lib/helpers/useSeeMore'
import StrapiHeader from '../Header'

const TileGrid = ({ data }) => {
  const [isExpanded, _setIsExpanded] = useState(null)
  const tileGridData = data?.TileGrid || {}
  const {
    Heading,
    DisplayHeader,
    ViewAllLink,
    testId,
    NumOfColumnsMobile,
    CollapsedNumOfRowsDesktop,
    CollapsedNumOfRowsMobile,
    Spacing,
    IsCollapsableDesktop,
    IsCollapsableMobile,
    Header,
    DisplayDesktop,
    DisplayMobile,
  } = tileGridData || {}
  const spaceBetweenDesktop = 60
  const spaceBetweenIpad = 40
  const NumOfColumnsIpad = NumOfColumnsMobile ? NumOfColumnsMobile + 2 : 5
  const tileWidthDesktop = calculateTileWidth(tileGridData, spaceBetweenDesktop)
  const desktopTiles = useMemo(() => getTiles(tileGridData, tileWidthDesktop, true), [tileGridData, tileWidthDesktop])
  const mobileTiles = useMemo(() => getTiles(tileGridData, 'auto', true), [tileGridData])
  const ipadTiles = useMemo(() => getTiles(tileGridData, 'auto', true), [tileGridData])
  const { generateTestId } = useTest()

  const {
    gridContainerRef: gridContainerRefDesktop,
    handleSeeMoreClick: handleSeeMoreClickDesktop,
    isSeeMore: isSeeMoreDesktop,
    isSeeLess: isSeeLessDesktop,
  } = useSeeMore({
    numOfRowsCollapsed: CollapsedNumOfRowsDesktop,
    isExpanded,
    _setIsExpanded,
    isCollapsable: IsCollapsableDesktop,
  })

  const {
    gridContainerRef: gridContainerRefIpad,
    handleSeeMoreClick: handleSeeMoreClickIpad,
    isSeeMore: isSeeMoreIpad,
    isSeeLess: isSeeLessIpad,
  } = useSeeMore({
    numOfRowsCollapsed: CollapsedNumOfRowsDesktop,
    isExpanded,
    _setIsExpanded,
    isCollapsable: IsCollapsableDesktop,
  })

  const {
    gridContainerRef: gridContainerRefMobile,
    handleSeeMoreClick: handleSeeMoreClickMobile,
    isSeeMore: isSeeMoreMobile,
    isSeeLess: isSeeLessMobile,
  } = useSeeMore({
    numOfRowsCollapsed: CollapsedNumOfRowsMobile,
    isExpanded,
    _setIsExpanded,
    isCollapsable: IsCollapsableMobile,
  })

  return (
    <Container Spacing={Spacing} DisplayDesktop={DisplayDesktop} DisplayMobile={DisplayMobile} data-testid={testId}>
      {DisplayHeader && !Header && (
        <HeadingAndSeeAllLink
          title={Heading}
          viewAllLink={ViewAllLink}
          headingStyles={{
            fontSize: '25px',
            fontSizeMobile: '20px',
            marginBottom: '35px',
          }}
        />
      )}
      {Header && <StrapiHeader data={Header} />}

      <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}>
        <DesktopTileGridWrapper
          ref={gridContainerRefDesktop}
          spaceBetweenDesktop={spaceBetweenDesktop}
          tileWidthDesktop={tileWidthDesktop}
          isExpandedDesktop={IsCollapsableDesktop === false || isExpanded}
          collapsedRows={CollapsedNumOfRowsDesktop}
          data-testid={generateTestId('component', 'large-tile-grid')}
        >
          {desktopTiles}
        </DesktopTileGridWrapper>
        {IsCollapsableDesktop !== false && (
          <SeeMoreWrapper>
            <SeeMoreButton href="#" onClick={isSeeMoreDesktop || isSeeLessDesktop ? handleSeeMoreClickDesktop : null}>
              {isSeeMoreDesktop && 'See More'}
              {isSeeLessDesktop && 'See Less'}
            </SeeMoreButton>
          </SeeMoreWrapper>
        )}
      </Box>
      <Box sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
        <IpadTileGridWrapper
          ref={gridContainerRefIpad}
          spaceBetweenIpad={spaceBetweenIpad}
          numOfColumns={NumOfColumnsIpad}
          isExpandedIpad={IsCollapsableDesktop === false || isExpanded}
          collapsedRows={CollapsedNumOfRowsDesktop}
          data-testid={generateTestId('component', 'medium-tile-grid')}
        >
          {ipadTiles}
        </IpadTileGridWrapper>
        {IsCollapsableDesktop !== false && (
          <SeeMoreWrapper>
            <SeeMoreButton href="#" onClick={isSeeMoreIpad || isSeeLessIpad ? handleSeeMoreClickIpad : null}>
              {isSeeMoreIpad && 'See More'}
              {isSeeLessIpad && 'See Less'}
            </SeeMoreButton>
          </SeeMoreWrapper>
        )}
      </Box>

      <Box sx={{ display: { xs: 'block', sm: 'none', md: 'none' } }}>
        <MobileTileGridWrapper
          numOfColumns={NumOfColumnsMobile}
          collapsedRows={IsCollapsableMobile === false || CollapsedNumOfRowsMobile}
          isExpandedMobile={isExpanded}
          ref={gridContainerRefMobile}
          data-testid={generateTestId('component', 'small-tile-grid')}
        >
          {mobileTiles}
        </MobileTileGridWrapper>
        {IsCollapsableMobile !== false && (
          <SeeMoreWrapper>
            <SeeMoreButton href="#" onClick={isSeeMoreMobile || isSeeLessMobile ? handleSeeMoreClickMobile : null}>
              {isSeeMoreMobile && 'See More'}
              {isSeeLessMobile && 'See Less'}
            </SeeMoreButton>
          </SeeMoreWrapper>
        )}
      </Box>
    </Container>
  )
}

TileGrid.propTypes = {
  data: PropTypes.object.isRequired,
}

export default TileGrid

export const StrapiTileGridFragment = graphql`
  fragment StrapiTileGridFragment on StrapiTileGrid {
    id
    testId
    Heading
    Spacing {
      TopPadding
      BottomPadding
    }
    DisplayHeader
    NumOfColumnsMobile
    MaxNumOfColumnsDesktop
    CollapsedNumOfRowsDesktop
    CollapsedNumOfRowsMobile
    ViewAllLink {
      Link {
        ...StrapiLinkFragment
      }
    }
    Tiles {
      __typename
      ... on BaseCategoryTile {
        CategoryTile {
          ...StrapiCategoryTileFragment
        }
      }
    }
    IsCollapsableDesktop
    IsCollapsableMobile
    Header {
      ...StrapiHeaderFragment
    }
    DisplayDesktop
    DisplayMobile
  }
`
