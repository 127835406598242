import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { css, styled } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { connectCurrentRefinements } from 'react-instantsearch-dom'

const CloseBtnIcon = styled('div')`
  display: flex;
  border-radius: 50%;
  background-color: #0053a0;
  color: #fff;
  margin: -1px 0 -1px 8px;

  & > svg {
    width: 20px;
    height: 20px;
    padding: 2px;
  }
`

const RefinementsWrapper = styled('div')(
  ({ theme }) => css`
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
    min-height: 32px;
    padding: 20px 20px 5px 20px;
    ${theme.breakpoints.down('md')} {
      margin-bottom: 10px;
      &:empty {
        padding: 0;
        min-height: 0;
        margin-bottom: 0;
      }
    }
  `,
)

const PillLink = styled('a')`
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;
  color: #0053a0;
  text-transform: capitalize;
  padding: 6px 10px;
  border: 1px solid #0053a0;
  border-radius: 100px;
  min-height: 32px;
`

const CustomConnectedCurrentRefinementsBase = ({ searchState, items = [], refine, createURL }) => (
  <RefinementsWrapper>
    {items.length > 0 && (
      <>
        {Object.entries(searchState.refinementList || {}).map(([key, value]) => {
          if (Array.isArray(value)) {
            return value.map(item => {
              const connectedItem = items.find(i => i.attribute === key).items.find(i => i.label === item)

              return (
                <PillLink
                  key={`${key}-${item}`}
                  href={createURL(connectedItem.value)}
                  onClick={event => {
                    event.preventDefault()
                    refine(connectedItem.value)
                  }}
                >
                  {item}
                  <CloseBtnIcon>
                    <CloseIcon />
                  </CloseBtnIcon>
                </PillLink>
              )
            })
          }

          if (!value) {
            return null
          }

          const connectedItem = items.find(i => i.attribute === key).items.find(i => i.label === value)

          return (
            <PillLink
              key={`${key}-${value}`}
              href={createURL(connectedItem.value)}
              onClick={event => {
                event.preventDefault()
                refine(connectedItem.value)
              }}
            >
              {value}
              <CloseBtnIcon>
                <CloseIcon />
              </CloseBtnIcon>
            </PillLink>
          )
        })}
        {JSON.stringify(searchState.refinementList) !== '{}' && (
          <PillLink
            href="#"
            onClick={event => {
              event.preventDefault()
              refine(items)
            }}
          >
            Clear All
          </PillLink>
        )}
      </>
    )}
  </RefinementsWrapper>
)

CustomConnectedCurrentRefinementsBase.propTypes = {
  searchState: PropTypes.shape({
    filters: PropTypes.object,
    refinementList: PropTypes.object,
  }),
  items: PropTypes.array,
  refine: PropTypes.func,
  createURL: PropTypes.func,
}

export const CustomConnectedCurrentRefinements = connectCurrentRefinements(CustomConnectedCurrentRefinementsBase)

export const CustomDisconnectedCurrentRefinements = ({ clearState, searchState, applyStateChange, getUrl }) => (
  <RefinementsWrapper>
    {Object.entries(searchState.filters || {}).map(([key, value]) =>
      value.map(item => (
        <PillLink
          key={`${key}-${item}`}
          href={getUrl(key, item)}
          onClick={event => {
            event.preventDefault()
            applyStateChange(key, item)
          }}
        >
          {item}
          <CloseBtnIcon>
            <CloseIcon />
          </CloseBtnIcon>
        </PillLink>
      )),
    )}
    {JSON.stringify(searchState.filters) !== '{}' && (
      <PillLink
        href="#"
        onClick={event => {
          event.preventDefault()
          clearState()
        }}
      >
        Clear All
      </PillLink>
    )}
  </RefinementsWrapper>
)

CustomDisconnectedCurrentRefinements.propTypes = {
  clearState: PropTypes.func,
  searchState: PropTypes.shape({
    filters: PropTypes.object,
  }),
  applyStateChange: PropTypes.func,
  getUrl: PropTypes.func,
}
