import React, { useMemo, useState } from 'react'
import { func, array, string } from 'prop-types'
import styled from 'styled-components'
import { removeFirstAndLastSlash } from '@helpers/string-helper'
import '@assets/css/components/search/search-filters.sass'
import { breakPoints } from '@constants/styles'
import RefinementList from './plp-parts/RefinementList'

const SearchFiltersWrapper = styled.div`
  @media screen and (max-width: ${breakPoints['large-min']}) {
    margin-bottom: 1rem;
  }
`

const FiltersWrapper = styled.div`
  @media screen and (max-width: ${breakPoints['large-max']}) {
    display: flex;
    flex-direction: column;
  }

  &:active,
  &:focus {
    outline: none;
  }
`

const SearchFilters = ({ attributes, matchPath, applyStateChange, getUrl }) => {
  let pathname = ''
  if (typeof window !== 'undefined') {
    pathname = window.location.pathname
  }

  pathname = removeFirstAndLastSlash(pathname || matchPath)

  const [drawer, setDrawer] = useState({
    open: false,
    content: null,
  })

  const closeDrawer = () => {
    setDrawer({
      open: false,
      content: null,
    })
  }

  const INFO_BUTTONS_FUNCTIONS = {
    movement: () => {
      setDrawer({
        open: true,
        content: 'movement',
      })
    },
  }

  return (
    <SearchFiltersWrapper>
      <FiltersWrapper role="navigation" aria-label="Filter Results" tabIndex="-1">
        {attributes &&
          attributes.map(({ facetName, items }, index) => (
            <RefinementList
              key={facetName}
              attribute={facetName}
              pathname={pathname}
              limit={100}
              items={items}
              applyStateChange={applyStateChange}
              getUrl={getUrl}
              infoButton={
                INFO_BUTTONS_FUNCTIONS[facetName]
                  ? { drawer, openDrawer: INFO_BUTTONS_FUNCTIONS[facetName], closeDrawer }
                  : null
              }
            />
          ))}
      </FiltersWrapper>
    </SearchFiltersWrapper>
  )
}

SearchFilters.propTypes = {
  attributes: array,
  matchPath: string,
  applyStateChange: func,
  getUrl: func,
}

export default SearchFilters
