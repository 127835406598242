import React from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Box } from '@material-ui/core'

import MosaicGrid from '../../../../@rtg2022/components/organisms/MosaicGrid/MosaicGrid'

const StrapiMosaicGrid = ({ data }) => {
  const { MosaicTiles, testId, DisplaySettings, Title } = data?.MosaicGrid

  const displayOptions = {
    desktop: {
      xs: 'none',
      sm: 'none',
      md: 'block',
    },
    mobile: {
      xs: 'block',
      sm: 'block',
      md: 'none',
    },
    both: {
      xs: 'block',
      sm: 'block',
      md: 'block',
    },
  }

  const displaySetting = displayOptions[DisplaySettings.toLowerCase()]

  return (
    <Box
      sx={{
        display: displaySetting,
        margin: { sm: '0', md: '20px 0' },
      }}
    >
      <MosaicGrid mosaicItems={MosaicTiles} mosaicId={testId} mosaicTitle={Title} displaySettings={DisplaySettings} />
    </Box>
  )
}

StrapiMosaicGrid.propTypes = {
  data: PropTypes.object,
}

export default StrapiMosaicGrid

export const StrapiMosaicGridFragment = graphql`
  fragment StrapiMosaicGridFragment on StrapiMosaicGrid {
    id
    testId
    Title
    DisplaySettings
    MosaicTiles {
      MosaicGridTile {
        TileColumnsAndRows
        FontColor {
          ColorHex
        }
        TileLink {
          ...StrapiLinkFragment
        }
        DisplayLinkText
        Heading {
          data {
            id
            childMarkdownRemark {
              html
            }
          }
        }
        Image {
          ...StrapiImageFragment
        }
        BackgroundColor {
          ColorHex
        }
      }
    }
  }
`
