import React from 'react'
import PropTypes from 'prop-types'

const ExpandSvg = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height="24px"
    viewBox="0 0 24 24"
    width="24px"
    fill="inherit"
    aria-hidden="true"
    role="presentation"
    alt=""
  >
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" />
  </svg>
)

ExpandSvg.propTypes = {
  className: PropTypes.string,
}

export default ExpandSvg
