// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Card, CardActionArea, CardMedia, CardContent, Typography, styled, Box } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { graphql } from 'gatsby'
// hooks
import useHoverElevation from '@hooks/useHoverElevation'
// components
import SaleFlag from '../../../@rtg2022/components/atoms/SaleFlag/SaleFlag'
import StrapiImage from './Image'
import { getLinkProps } from './Link'
import StrapiButton from './Button'
import ImageWithSku from './ImageWithSku'

const Icons = props => (
  <span {...props}>
    <ArrowForwardIosIcon />
    <ArrowForwardIosIcon />
  </span>
)

const CardStyles = styled(Card)(({ theme, link }) => {
  const cardAnimation = link
    ? {
        transition: theme.transitions.create(['all'], {
          easing: theme.transitions.easing.easeIn,
          duration: theme.transitions.duration.short,
        }),
        '&:hover': {
          transform: ' scale(1.01)',
        },
        '& *:focus': {
          outline: 'none !important',
        },
      }
    : {}

  return {
    ...cardAnimation,
    borderRadius: '0px',
    cursor: !link ? 'default' : 'pointer',
    '& span[role=button] div[data-testid=saleFlag]': {
      top: 0,
      left: 0,
    },
  }
})

const CardV2Styles = styled(Card)({
  backgroundColor: 'transparent',
  borderRadius: '0px',
  boxShadow: 'none',
  '& span[role=button] div[data-testid=saleFlag]': {
    top: 0,
    left: 0,
  },
})

const CardActionAreaStyles = styled(CardActionArea)(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: null,
  },
}))

const CardActionAreaStylesV2 = styled('div')(({ theme }) => ({
  position: 'relative',
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
}))

const CardMediaStyles = styled(CardMedia, {
  shouldForwardProp: prop => !['collectionTileV2'].includes(prop),
})(({ collectionTileV2 }) => ({
  height: 'auto',
  maxHeight: 650,
  margin: collectionTileV2 ? '0' : '0 auto',
  maxWidth: collectionTileV2 ? null : 650,
  objectFit: 'cover',
  width: '100%',
}))

const CardContentStyles = styled(CardContent, {
  shouldForwardProp: prop => !['collectionTileV2'].includes(prop),
})(({ theme, collectionTileV2, description }) => {
  let cardContentPadding = { padding: null }
  if (collectionTileV2 && description) cardContentPadding = { padding: '16px 0' }
  if (collectionTileV2 && !description) cardContentPadding = { padding: '0' }

  return {
    ...cardContentPadding,
    alignItems: collectionTileV2 ? 'start' : 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: collectionTileV2 ? 'flex-start' : 'space-between',
    minHeight: collectionTileV2 && !description ? 'auto' : 260,
    textAlign: collectionTileV2 ? 'start' : 'center',
    width: collectionTileV2 ? '100%' : null,
    div: {
      width: collectionTileV2 ? '100%' : null,
    },
    '&:last-child': {
      paddingBottom: collectionTileV2 && description ? 24 : 0,
    },
    [theme.breakpoints.down('md')]: {
      minHeight: 'auto',
    },
  }
})

const CallToActionStyles = styled(Typography, {
  shouldForwardProp: prop => !['collectionTileV2'].includes(prop),
})(({ collectionTileV2 }) => ({
  color: collectionTileV2 ? '#003566' : null,
  display: 'flex',
  flexWrap: collectionTileV2 ? 'wrap' : null,
  fontWeight: 600,
  justifyContent: collectionTileV2 ? 'start' : 'center',
}))

const HeadingStyles = styled(Typography, {
  shouldForwardProp: prop => !['collectionTileV2'].includes(prop),
})(({ theme, collectionTileV2, description }) => ({
  color: collectionTileV2 ? '#003566' : null,
  fontWeight: 600,
  marginBottom: collectionTileV2 && description ? '10px' : null,
  fontSize: collectionTileV2 ? '30px' : null,
  [theme.breakpoints.down('md')]: {
    fontSize: collectionTileV2 ? '16px' : null,
  },
  padding: collectionTileV2 && !description ? '0' : null,
}))

const IconsStyles = styled(Icons)({
  display: 'inline-block',
  height: 24,
  position: 'relative',
  width: 24,
  '& svg': {
    position: 'absolute',
    top: '4px',
    fontSize: '15px',
  },
  '& svg:first-child': {
    left: 0,
  },
  '& svg:nth-child(2)': {
    left: 8,
  },
})

const ShopCollectionStyles = styled('span')({
  alignItems: 'center',
  display: 'flex',
  fontSize: '14px',
})

const CollectionButtons = styled('div')(({ theme, collectionTileV2 }) => ({
  backgroundColor: 'transparent',
  color: '#003566',
  display: 'flex',
  width: 'fit-content !important',
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '& button': {
    display: 'contents',
  },
  '& a': {
    borderRadius: '4px',
    fontSize: '14px',
    fontWeight: 600,
    margin: '5px',
    padding: collectionTileV2 ? '5px 20px' : '10px 20px',
    [theme.breakpoints.down('md')]: {
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  [theme.breakpoints.down('md')]: {
    width: collectionTileV2 ? '100% !important' : null,
  },
}))

const BlogCardContentStyles = styled('div')(() => ({
  marginTop: '8px',
}))

const BlogHeadingStyles = styled('div')(({ theme }) => ({
  fontSize: '25px',
  fontWeight: '500',
  lineHeight: '34px',
  margin: '8px 0',
  textAlign: 'left',
  textDecoration: 'capitalize',
  [theme.breakpoints.down('sm')]: {
    fontSize: '20px',
    lineHeight: '28px',
    margin: '10px 0',
  },
}))

const BlogDescrStyles = styled('div')(({ theme }) => ({
  color: '#151515',
  fontSize: '18px',
  fontWeight: '400',
  lineHeight: '22px',
  textAlign: 'left',
  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
    lineHeight: '18px',
  },
}))

const DescriptionStyles = styled(Typography)(({ theme, DescriptionFullWidth }) => ({
  marginBottom: '1em',
  width: DescriptionFullWidth ? '100%' : '65%',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  },
}))

const SalesFlagWrapper = styled('div')(({ theme }) => ({
  '& .MuiBox-root': {
    left: '10px',
    top: '10px',
    padding: '5px 10px',
    [theme.breakpoints.down('md')]: {
      left: '5px',
      top: '5px',
      borderRadius: '4px',
      padding: '4px 8px',
      '& .MuiTypography-root': {
        fontSize: '12px',
        lineHeight: '16px',
      },
    },
  },
}))

const StrapiCollectionTile = ({ data, collectionTileDesignV2CG = null, blogCollection = false }) => {
  const { onMouseEnter, onMouseLeave, elevation } = useHoverElevation()
  const hasImageWithSku =
    !!data?.ImageWithSku?.ImageWithSku?.Image?.url || !!data?.ImageWithSku?.ImageWithSku?.MobileImage?.url
  const hasImage = data?.Image?.url || hasImageWithSku
  const link = data?.ImageWithSku?.ImageWithSku?.Link?.Link || data?.Link?.Link
  const collectionTileV2 = collectionTileDesignV2CG !== null ? collectionTileDesignV2CG : data?.CollectionTileDesignV2
  const buttonProps = link ? getLinkProps(data, link, { category: 'collection tile' }) : {}
  const areBtnsShown = data?.ShowButtonsV2 !== false && data?.Buttons.length > 0
  const collectionDescription = data?.LongDescription?.data?.LongDescription || data?.Description
  const ButtonsText = data?.ButtonsCustomizedText ? data?.ButtonsCustomizedText : 'SHOP COLLECTION:'
  const imageWithSkuData = data?.ImageWithSku
  const strapiImageData = {
    desktop: data?.Image,
    alt: data?.Heading,
  }

  const renderCardImage = () => {
    if (hasImageWithSku) {
      return (
        <CardMediaStyles
          collectionTileV2={collectionTileV2}
          component={ImageWithSku}
          data={imageWithSkuData}
          title={data?.Title}
        />
      )
    }
    return (
      <CardMediaStyles
        collectionTileV2={collectionTileV2}
        component={StrapiImage}
        data={strapiImageData}
        title={data?.Title}
      />
    )
  }

  const cardMediaV1 = () => (
    <div>
      {data?.OnSale && <SaleFlag label="SALE" />}
      {hasImage && renderCardImage()}
      <CardContentStyles collectionTileV2={collectionTileV2}>
        <div>
          <HeadingStyles collectionTileV2={collectionTileV2} gutterBottom variant="h6" component="h4">
            {data?.Heading || ''}
          </HeadingStyles>
          <Typography gutterBottom variant="body1">
            {collectionDescription || ''}
          </Typography>
        </div>
        <CallToActionStyles collectionTileV2={collectionTileV2} variant="body1">
          VIEW THE COLLECTION &nbsp;
          <IconsStyles />
        </CallToActionStyles>
      </CardContentStyles>
    </div>
  )

  if (blogCollection) {
    return (
      <CardV2Styles data-testid={data?.testId}>
        <CardActionAreaStylesV2>
          {data?.OnSale && (
            <SalesFlagWrapper>
              <SaleFlag label="SALE" />
            </SalesFlagWrapper>
          )}
          <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <ImageWithSku data={data?.ImageWithSku} height={305} width={460} />
          </Box>
          <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
            <ImageWithSku data={data?.ImageWithSku} height={152} width={228} />
          </Box>
          <BlogCardContentStyles>
            <BlogHeadingStyles>{data?.Heading || ''}</BlogHeadingStyles>
            {collectionDescription && <BlogDescrStyles>{collectionDescription}</BlogDescrStyles>}
          </BlogCardContentStyles>
        </CardActionAreaStylesV2>
      </CardV2Styles>
    )
  }

  return (
    <>
      {!collectionTileV2 && (
        <>
          {link && (
            <CardStyles
              data-testid={data?.testId}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              elevation={elevation}
              link={link}
            >
              <CardActionAreaStyles {...buttonProps}>{cardMediaV1()}</CardActionAreaStyles>
            </CardStyles>
          )}
          {!link && <CardStyles data-testid={data?.testId}>{cardMediaV1()}</CardStyles>}
        </>
      )}
      {collectionTileV2 && (
        <CardV2Styles data-testid={data?.testId}>
          <CardActionAreaStylesV2 collectionTileV2={collectionTileV2}>
            {data?.OnSale && (
              <SalesFlagWrapper>
                <SaleFlag label="SALE" />
              </SalesFlagWrapper>
            )}
            {hasImage && link && <CardActionArea {...buttonProps}>{renderCardImage()}</CardActionArea>}
            {hasImage && !link && renderCardImage()}
            <CardContentStyles collectionTileV2={collectionTileV2} description={collectionDescription}>
              <div>
                <HeadingStyles
                  collectionTileV2={collectionTileV2}
                  description={collectionDescription}
                  gutterBottom
                  variant="h6"
                  component="h4"
                >
                  {data?.Heading?.toUpperCase() || ''}
                </HeadingStyles>
                {collectionDescription && (
                  <DescriptionStyles DescriptionFullWidth={data?.DescriptionFullWidth} gutterBottom variant="body1">
                    {collectionDescription}
                  </DescriptionStyles>
                )}
              </div>
              {areBtnsShown && (
                <CallToActionStyles collectionTileV2={collectionTileV2} variant="body1" component="div">
                  {data?.DisplayButtonsText !== false && <ShopCollectionStyles>{ButtonsText}</ShopCollectionStyles>}
                  <CollectionButtons className="collection-buttons" collectionTileV2={collectionTileV2}>
                    {data?.Buttons.map(button => (
                      <StrapiButton key={button.id} data={button.Button} />
                    ))}
                  </CollectionButtons>
                </CallToActionStyles>
              )}
            </CardContentStyles>
          </CardActionAreaStylesV2>
        </CardV2Styles>
      )}
    </>
  )
}

StrapiCollectionTile.propTypes = {
  data: PropTypes.object.isRequired,
  collectionTileDesignV2CG: PropTypes.bool,
  blogCollection: PropTypes.bool,
}

export default StrapiCollectionTile

export const StrapiCollectionTileFragment = graphql`
  fragment StrapiCollectionTileFragment on StrapiCollectionTile {
    id
    testId
    Description
    Heading
    Title
    ImageAltText
    Image {
      ...StrapiImageFragment
    }
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
    CollectionTileDesignV2
    Buttons {
      Button {
        ...StrapiButtonFragment
      }
    }
    ShowButtonsV2
    OnSale
    ImageWithSku {
      ...BaseImageWithSkuFragment
    }
    LongDescription {
      data {
        LongDescription
      }
    }
    DisplayButtonsText
    ButtonsCustomizedText
    DescriptionFullWidth
  }
`
