import React from 'react'
import CategoryTileCircle from '../CategoryTileCircle/CategoryTileCircle'

export const generateItemKey = (TileGrid, item) => (typeName = '') =>
  `${TileGrid?.id}-${item.__typename}-${item?.[typeName]?.id || item?.[typeName]}`.toLowerCase()

const getTiles = (TileGrid, tileWidth = 'auto', noBorder = false) => {
  const items = TileGrid?.Tiles || []

  return items
    .map((item, index) => {
      const getKey = generateItemKey(TileGrid, item)
      const View = {
        BaseCategoryTile: item?.CategoryTile && (
          <CategoryTileCircle
            data={item?.CategoryTile}
            key={getKey('CategoryTile')}
            width={tileWidth}
            noBorder={noBorder}
          />
        ),
      }
      return View[item.__typename] || null
    })
    .filter(component => Boolean(component))
}

export const calculateTileWidth = (tileGridData, spaceBetween = 60) => {
  const { MaxNumOfColumnsDesktop } = tileGridData || { MaxNumOfColumnsDesktop: 7 }
  // app-wide constants
  const screenMaxWidth = 1664
  const screenPadding = 15
  return Math.floor(
    (screenMaxWidth - screenPadding * 2 - spaceBetween * (MaxNumOfColumnsDesktop - 1)) / MaxNumOfColumnsDesktop,
  )
}

export default getTiles
