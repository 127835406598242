import React from 'react'
import { graphql } from 'gatsby'

import PropTypes from 'prop-types'
import { Grid } from '@mui/material'
import StrapiLink from '@templates/strapi-cms/content-types/Link'

import ImageList from '../../../@rtg2022/components/organisms/ImageList/ImageList'

const toListItem = listItem => ({
  id: listItem?.id,
  gradient: listItem?.ShowGradient || false,
  product_image: {
    small: listItem?.MobileImage?.url,
    large: listItem?.DesktopImage?.url,
  },
  product_label: listItem?.ItemLink?.DisplayText || listItem?.ItemLink?.Title,
  route: listItem?.ItemLink?.InternalUrl || listItem?.ItemLink?.ExternalUrl,
  featured: listItem?.featured,
  sale: listItem?.sale,
  itemShapeAndSize: listItem?.ItemShapeAndSize,
  showDisplayName: listItem?.ShowDisplayName,
  markdown: listItem?.Markdown,
})

const StrapiImageList = ({ data }) => {
  const HeadingLink = data?.ImageList?.HeadingLink?.Link
  const fontColor = data?.ImageList?.FontColor?.ColorHex
  const margin = {
    top: data?.ImageList?.Margin?.Top,
    bottom: data?.ImageList?.Margin?.Bottom,
    left: data?.ImageList?.Margin?.Left,
    right: data?.ImageList?.Margin?.Right,
  }
  const items = data?.ImageList?.ImageListItem || []

  if (!data?.ImageList) return null

  return (
    <Grid item md={12} data-testid={data?.ImageList?.testId}>
      <ImageList
        heading={HeadingLink?.DisplayText || HeadingLink?.Title}
        fontColor={fontColor}
        margin={margin}
        itemData={items.map(toListItem)}
        strapiLink={HeadingLink && <StrapiLink data={{ ...HeadingLink, DisplayText: 'SEE ALL' }} />}
        strapiGap={data?.ImageList?.ImageListSpacing}
        strapiAnimation={data?.ImageList?.ShowAnimation}
      />
    </Grid>
  )
}

StrapiImageList.propTypes = {
  data: PropTypes.object,
}

export default StrapiImageList

export const StrapiImageListFragment = graphql`
  fragment StrapiImageListFragment on StrapiImageList {
    id
    testId
    HeadingLink {
      Link {
        ...StrapiLinkFragment
      }
    }
    Layout
    ImageListItem {
      id
      sale
      featured
      ItemShapeAndSize
      ShowDisplayName
      ShowGradient
      MobileImage {
        ...StrapiImageFragment
      }
      DesktopImage {
        ...StrapiImageFragment
      }
      ItemLink {
        ...StrapiLinkFragment
      }
      Markdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
    }
    FontColor {
      ColorHex
    }
    Margin {
      Top
      Bottom
      Left
      Right
    }
    ImageListSpacing
    ShowAnimation
  }
`
