export const isHero = (Slider = null) => {
  const title = Slider?.Title
  if (!title) return false
  const regexp = /hero/gi
  return regexp.test(title)
}

export const isProductTile = (Slider = null) => {
  const typeName = Slider?.Slides?.length && Slider.Slides[0].__typename
  if (!typeName) return false
  const regexp = /BaseProductTile/gi
  return regexp.test(typeName)
}

// Gets selcted visual filter index, used to set activeIndex.
// Returns index, if the index exists in the array, and if scrollbar should fade or not.
export const getVisualFilterParams = (Slider, isVisualFilter) => {
  let selectedVisualFilterIndex
  if (isVisualFilter) {
    const windowLocationPathname = typeof window !== 'undefined' ? window.location?.pathname : null
    selectedVisualFilterIndex = Slider?.Slides?.findIndex?.(
      slide => slide?.CategoryTile?.Link?.Link?.InternalUrl === removeTrailingSlash(windowLocationPathname),
    )
  }
  const selectedVisualFilterIndexExists =
    selectedVisualFilterIndex >= 0 && selectedVisualFilterIndex <= Slider?.Slides?.length

  const noFadeoutForVisualFilterSelection =
    (!isVisualFilter && !selectedVisualFilterIndex) || (isVisualFilter && selectedVisualFilterIndex < 0)

  return {
    selectedVisualFilterIndex,
    selectedVisualFilterIndexExists,
    noFadeoutForVisualFilterSelection,
  }
}

export const removeTrailingSlash = (str = '') => (str?.endsWith('/') ? str.slice(0, -1) : str)
