import React from 'react'
import { objectOf, any } from 'prop-types'
import classNames from 'classnames'
import { Grid, makeStyles, useMediaQuery } from '@material-ui/core'
import { graphql } from 'gatsby'
import Cookies from 'universal-cookie'

import PageButtonList from '@templates/strapi-cms/content-types/ButtonList'
import PageSlider from '@templates/strapi-cms/content-types/SliderWrapper'
import PageBanner from '@templates/strapi-cms/content-types/Banner'
import PageHtml from '@templates/strapi-cms/content-types/HTML'
import PageGrid from '@templates/strapi-cms/content-types/Grid'
import PageMarkdown from '@templates/strapi-cms/content-types/Markdown'
import PageImageList from '@templates/strapi-cms/content-types/ImageList'
import PageTileGrid from '@templates/strapi-cms/content-types/TileGrid'
import BaseCollectionTileImageGallery from '@templates/strapi-cms/content-types/CollectionTileImageGallery'
import PageBannerSwiper from '@templates/strapi-cms/content-types/BannerSwiper/BannerSwiper'
import PageSearchQuery from '@templates/strapi-cms/content-types/SearchQuery'
import PageMosaicGrid from '@templates/strapi-cms/content-types/MosaicGrid/MosaicGrid'

// helpers
import { getTypeKey } from '@helpers/strapi'

const cookies = new Cookies()

const useStyles = makeStyles(theme => ({
  contentGroup: ({ styles, DisplayDesktop, DisplayMobile }) => ({
    paddingLeft: styles?.paddingLeft,
    paddingRight: styles?.paddingRight,
    paddingTop: styles?.paddingTopDesktop,
    paddingBottom: styles?.paddingBottomDesktop,
    maxWidth: styles?.maxWidth,
    marginLeft: styles?.marginLeft,
    marginRight: styles?.marginLeft,
    display: DisplayDesktop === false ? 'none' : 'block',
    [theme.breakpoints.down('xs')]: {
      display: DisplayMobile === false ? 'none' : 'block',
      paddingTop: styles?.paddingTopMobile,
      paddingBottom: styles?.paddingBottomMobile,
    },
  }),
  mobileGrayBackgroundWrapper: ({ BackgroundColorMobile }) => ({
    [theme.breakpoints.down('xs')]: {
      margin: '0 -15px',
      padding: '0 15px',
      backgroundColor: BackgroundColorMobile ?? 'inherit',
      width: 'calc(100% + 30px) !important',
    },
  }),
}))

export const StrapiMappings = {
  PageBanner,
  PageMarkdown,
  PageButtonList,
  PageGrid,
  PageSlider,
  PageHtml,
  PageImageList,
  BaseCollectionTileImageGallery,
  PageTileGrid,
  PageBannerSwiper,
  PageSearchQuery,
  PageMosaicGrid,
}

const CreateContent = (contentGroup, index, horizontalSpacing, contentTab) => {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('xs'))
  const {
    id,
    testId,
    Content,
    Spacing,
    MobileSpacing,
    Title,
    DisplayDesktop,
    DisplayMobile,
    CollectionTileDesignV2,
    EscProvider,
    BackgroundColorMobile,
  } = contentGroup
  const contentTabData = { contentTab, CollectionTileDesignV2 }
  const cookieESCProvider = cookies.get('__Host-rtg_location')?.esc_provider?.toLowerCase()

  const styles = {
    paddingLeft: horizontalSpacing?.LeftPadding,
    paddingRight: horizontalSpacing?.RightPadding,
    paddingTopDesktop: Spacing?.TopPadding > 0 ? `${Spacing?.TopPadding}px` : '0',
    paddingBottomDesktop: Spacing?.BottomPadding > 0 ? `${Spacing?.BottomPadding}px` : '0',
    paddingTopMobile: MobileSpacing?.TopPadding > 0 ? `${MobileSpacing?.TopPadding}px` : '0',
    paddingBottomMobile: MobileSpacing?.BottomPadding > 0 ? `${MobileSpacing?.BottomPadding}px` : '0',
    maxWidth: horizontalSpacing?.LeftPadding > 0 || horizontalSpacing?.RightPadding > 0 ? '1440px' : null,
    marginLeft: horizontalSpacing?.LeftPadding > 0 || horizontalSpacing?.RightPadding > 0 ? 'auto' : null,
    marginRight: horizontalSpacing?.LeftPadding > 0 || horizontalSpacing?.RightPadding > 0 ? 'auto' : null,
  }

  const classes = useStyles({ styles, DisplayMobile, DisplayDesktop, BackgroundColorMobile })

  if ((isMobile && DisplayMobile === false) || (!isMobile && DisplayDesktop === false)) {
    return null
  }

  if (EscProvider && EscProvider?.toLowerCase() !== cookieESCProvider) {
    return null
  }

  return contentGroup ? (
    <div className={BackgroundColorMobile && classes.mobileGrayBackgroundWrapper}>
      <Grid
        container
        item
        sm={12}
        className={classNames(classes.contentGroup, 'page-content-group')}
        key={id}
        data-testid={testId}
        alignItems="center"
        justifyContent="center"
      >
        {Content &&
          Array.isArray(Content) &&
          Content.map((subContent, subIndex) => {
            const Component = StrapiMappings[subContent?.__typename]
            return Component ? (
              <Component
                // eslint-disable-next-line react/destructuring-assignment
                data={{ ...subContent, contentGroupTitle: Title }}
                contentIndex={`${index}:${subIndex}`}
                key={getTypeKey(subContent, 'content-group-')}
                contentTabData={contentTabData}
              />
            ) : null
          })}
      </Grid>
    </div>
  ) : null
}

export function ContentGroup({
  data,
  index = 0,
  horizontalSpacing = { LeftPadding: 0, RightPadding: 0 },
  contentTab = false,
}) {
  const contentGroup = data?.strapiContentGroup ?? data?.ContentGroup ?? data
  return contentGroup && CreateContent(contentGroup, index, horizontalSpacing, contentTab)
}

ContentGroup.propTypes = {
  data: objectOf(any).isRequired,
}

export const StrapiContentGroupFragment = graphql`
  fragment StrapiContentGroupFragment on StrapiContentGroup {
    id
    testId
    Content {
      __typename
      ... on PageGrid {
        Grid {
          ...StrapiPageGridFragment
        }
      }
      ... on PageBanner {
        Banner {
          ...StrapiPageBannerFragment
        }
      }
      ... on PageSlider {
        Slider {
          ...StrapiSliderfragment
        }
      }
      ... on PageMarkdown {
        Markdown {
          ...StrapiPageMarkdownFragment
        }
      }
      ... on PageButtonList {
        ButtonList {
          ...StrapiButtonListFragent
        }
      }
      ... on PageHtml {
        HTML {
          ...StrapiHtmlFragment
        }
      }
      ... on BaseCollectionTileImageGallery {
        CollectionTileImageGallery {
          ...StrapiCollectionTileImageGalleryFragment
        }
      }
      ... on PageImageList {
        ImageList {
          ...StrapiImageListFragment
        }
      }
      ... on PageBannerSwiper {
        BannerSwiper {
          ...StrapiPageBannerSwiperFragment
        }
      }
      ... on PageSearchQuery {
        SearchQuery {
          ...SearchQueryStrapiFragment
        }
      }
      ... on PageTileGrid {
        TileGrid {
          ...StrapiTileGridFragment
        }
      }
      ... on PageMosaicGrid {
        MosaicGrid {
          ...StrapiMosaicGridFragment
        }
      }
    }
    Title
    Spacing {
      TopPadding
      BottomPadding
    }
    MobileSpacing {
      TopPadding
      BottomPadding
    }
    DisplayDesktop
    DisplayMobile
    CollectionTileDesignV2
    EscProvider
    BackgroundColorMobile
  }
`

export default ContentGroup
