import PropTypes from 'prop-types'
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Paper, styled } from '@mui/material'
import { orderBy, isEmpty, isEqual, cloneDeep } from 'lodash'
import { sentryLogger } from '@helpers/sentry-logger'
import { getTileDataWithBanners } from '@helpers/product'
import { useLocation } from '@gatsbyjs/reach-router'
import { breakPoints, colors } from '@constants/styles'
import CircleCounter from '@components/shared/circle-counter'
import { useTest } from '@hooks/useTest'
import { CustomDisconnectedCurrentRefinements } from '@components/searchv2/connectors/CustomCurrentRefinements'
import StrapiSliderV2 from '@templates/strapi-cms/content-types/Slider-v2'
import Hits from './plp-parts/Hits'
import Stats from './plp-parts/Stats'
import Pagination from './plp-parts/Pagination'
import { refine } from './helpers/refine'
import { stateToUrl, initSearchState, windowPush } from './helpers/state'
import SearchResultsContext from './contexts/search-results-context'
import Drawer from './plp-parts/Drawer'
import Sidebar from './search-sidebar'

const LeftBarWrapper = styled(Paper)`
  display: none;
  background-color: ${colors.white};
  height: fit-content;
  min-width: 280px;
  padding: 16px;

  @media only screen and (min-width: ${breakPoints['large-max']}) {
    display: block;
  }
`

// 25px right might looks ramdom, but it accomodates the grid for a final spacing of 16px
const Wrapper = styled('div')`
  @media only screen and (min-width: ${breakPoints['large-min']}) {
    padding: 0;
    display: flex;
  }
`

const SidebarMobileWrapper = styled('div')`
  min-width: 280px;
  min-height: 100%;
  padding: 16px 10px;
`

export const SortFilter = styled('button')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #0053a0;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 17px;
  text-align: right;

  > span:first-child {
    margin-right: 5px;
  }

  :focus {
    outline: none;
  }
`
const Label = styled(Grid)`
  margin: 0 -15px 0 -15px;
  width: calc(100% + 30px);
  padding: 0 15px;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  position: sticky;
  top: 118px;
  z-index: 10;
  background-color: #fff;
  justify-content: space-between;
  @media (min-width: 768px) {
    top: 130px;
  }
`

const applyFilterChange = (newState, key, value) => {
  newState.filters = newState.filters ?? {}
  if (newState.filters?.[key]?.includes(value)) {
    newState.filters[key] = newState.filters[key].filter(item => item !== value)
    if (newState.filters[key].length === 0) delete newState.filters[key]
  } else {
    newState.filters[key] = newState.filters[key] ?? []
    newState.filters[key].push(value)
  }
}

const getRefinementList = (initialResults, refinedResults, displayFilters, searchState) => {
  const refinedFacets = refinedResults?.content?.facets || []
  const baseFacets = initialResults?.content?.facets || []
  const newFacetFilters = []
  const refinementFilters = searchState.filters
  displayFilters.forEach(attr => {
    const baseFacet = baseFacets.find(f => attr === f.name) ?? { data: {} }
    const refinedFacet = refinedFacets.find(f => attr === f.name) ?? {
      data: {},
    }
    const items = Object.keys(baseFacet.data).map(filter => ({
      label: filter,
      count: refinedFacet?.data?.[filter] ?? 0,
      isRefined: !!refinementFilters?.[baseFacet.name]?.includes(filter),
    }))
    const facetOrdering = refinedResults?._originalResponse?.results[0]?.renderingContent?.facetOrdering
    const reorder = facetOrdering?.facets?.order?.filter(val => val === attr)
    let newItems = []
    if (reorder?.length > 0) {
      const reorderArr = facetOrdering?.values[attr]?.order
      newItems =
        reorderArr.length &&
        items.sort((a, b) => {
          const aIndex = reorderArr.indexOf(a.label)
          const bIndex = reorderArr.indexOf(b.label)
          if (aIndex === -1) return 1
          if (bIndex === -1) return -1
          return aIndex - bIndex
        })
    }
    if (newItems.length > 1 || items.length > 1) {
      newFacetFilters.push({
        facetName: attr,
        items: newItems.length > 1 ? newItems : orderBy(items, ['label', 'count'], ['asc', 'desc']),
      })
    }
  })
  return newFacetFilters
}

const SearchResults = props => {
  const {
    displayFilters,
    source,
    matchPath,
    productType,
    queryData,
    plpBanners,
    searchResults,
    onFiltersChange,
    visualFilterData,
  } = props

  const location = useLocation()
  const initialSearchState = initSearchState(searchResults, location, displayFilters)

  const isMobile = useSelector(state => state.global.isMobile)
  const [filtersChanged, setFiltersChanged] = useState(false)
  const [showLabel, setShowLabel] = useState(false)
  const [openModalSidebar, setOpenModalSidebar] = useState(false)
  const [refinedSearchResults, setRefinedSearchResults] = useState(searchResults)
  const [refinementList, setRefinementList] = useState(
    getRefinementList(searchResults, searchResults, displayFilters, initSearchState),
  )
  const [searchState, setSearchState] = useState(initialSearchState)
  const [searching, setSearching] = useState(false)
  const [isInitialLoad, setIsInitialLoad] = useState(true)

  const { generateTestId } = useTest()

  const { hits } = refinedSearchResults?.content

  const isBaseFilters = useCallback(state => isEmpty(state.filters), [])

  const isBasePage = useCallback(state => isEqual(state.page, 1), [])

  const isBaseSortBy = useCallback(state => isEqual(state.sortBy, searchResults.state.index), [
    searchResults.state.index,
  ])

  const isBaseState = useCallback(
    state => isBaseFilters(state) && isBasePage(state) && isBaseSortBy(state),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const filterBaseProperties = useCallback(
    state => {
      const properties = {
        ...(!isBaseFilters(state) && state.filters),
        ...(!isBasePage(state) && { page: state.page }),
        ...(!isBaseSortBy(state) && { sortBy: state.sortBy }),
      }
      if (state.keywords) {
        properties.keywords = state.keywords
      }
      return properties
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const onSearchStateChange = useCallback(() => {
    // IMPORTANT!!! initialLoad of the page can consists of analytics params in the url, be careful while editing this function
    const url = isInitialLoad ? matchPath : stateToUrl(filterBaseProperties(searchState), location)
    setIsInitialLoad(false)
    windowPush(url)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchPath, location, searchState])

  const getNextState = useCallback(
    (key, value) => {
      const newState = cloneDeep(searchState)
      const isFilter = displayFilters.includes(key)
      if (isFilter) {
        applyFilterChange(newState, key, value)
      } else {
        newState[key] = value
      }
      if (key !== 'page') {
        newState.page = 1
      }
      return newState
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchState, displayFilters],
  )

  const applyStateChange = useCallback(
    (key, value) => {
      const state = getNextState(key, value)
      setSearchState(state)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getNextState],
  )

  const getUrl = useCallback(
    (key, value) => {
      const state = getNextState(key, value)
      const url = stateToUrl(filterBaseProperties(state), location)
      return url
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location],
  )

  const clearState = useCallback(() => {
    setSearchState({
      sortBy: searchState.sortBy,
      page: 1,
      filters: {},
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState.sortBy])

  const setStateFilters = useCallback(
    filters => {
      setSearchState({
        sortBy: searchState.sortBy,
        page: 1,
        filters,
      })
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [searchState.sortBy],
  )

  const clearSortBy = useCallback(() => {
    setSearchState({
      ...searchState,
      sortBy: searchResults.content.index,
    })
  }, [searchResults.content.index, searchState])

  const setFacetFilters = useCallback(() => {
    const newFacetFilters = getRefinementList(searchResults, refinedSearchResults, displayFilters, searchState)
    if (!isEqual(newFacetFilters, refinementList)) setRefinementList(newFacetFilters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refinedSearchResults?.content?.facets, searchResults?.content?.facets, searchState.filters])

  const captureSentryError = useCallback((errorType, message) => {
    const setMessage = `Search: (${source ? source.toUpperCase() : 'unknown'}) ${message}`
    sentryLogger({
      configureScope: {
        type: 'setExtra',
        page: source,
        query: queryData,
        props,
      },
    })

    if (errorType === 'error') {
      sentryLogger({
        captureException: { message: setMessage },
      })
    } else {
      sentryLogger({
        captureMessage: { message: setMessage },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const countFilters = () => Object.values(searchState.filters).reduce((acc, next) => acc + next.length, 0)

  const preparedHits = getTileDataWithBanners(hits, plpBanners)

  const stats = <Stats nbHits={refinedSearchResults?.content?.nbHits} searching={searching} />
  const filtersQuantity = countFilters()

  const applyFilters = async () => {
    setSearching(true)
    const results = await refine({
      oldState: {
        ...searchResults.content._state,
        attributesToRetrieve: [
          '*', // retrieves all attributes
          '-reviews', // except reviews
        ],
      },
      filters: searchState.filters,
      page: searchState.page,
      index: searchState.sortBy,
      plpBanners: plpBanners?.length,
    })

    setRefinedSearchResults(results)
    setFiltersChanged(true)
    setSearching(false)
  }

  useEffect(() => {
    setFacetFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refinedSearchResults?.content?.facets, searchResults?.content?.facets, searchState.filters])

  useEffect(() => {
    setFiltersChanged(false)
    setOpenModalSidebar(false)
    applyFilters()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setShowLabel(isMobile)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile])

  useEffect(() => {
    if (!isEqual(refinedSearchResults, searchResults) || !isBaseState(searchState)) {
      applyFilters()
    }
    onFiltersChange(searchState.filters)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchState])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(
    () => onSearchStateChange(),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refinedSearchResults],
  )

  useMemo(() => {
    // sorts refinementList in place
    const facetOrdering = [
      'color_family',
      'popularityFilters',
      searchResults?._originalResponse?.results[0]?.renderingContent?.facetOrdering?.facets?.order,
    ]
    if (facetOrdering) {
      return refinementList.sort((a, b) => {
        const indexA = facetOrdering.indexOf(a.facetName)
        const indexB = facetOrdering.indexOf(b.facetName)
        return indexA > indexB ? -1 : 1
      })
    }
    return refinementList
  }, [refinementList, searchResults])

  return (
    <SearchResultsContext.Provider
      value={{
        currentRefinements: searchState.filters,
        applyStateChange,
        getUrl,
        clearState,
      }}
    >
      {visualFilterData && (
        <div data-testid={generateTestId('search-results', 'visual-filter')}>
          <StrapiSliderV2 data={visualFilterData} sliderProps={{ displayFilters, setStateFilters }} isVisualFilter />
        </div>
      )}
      {/* Results + Sort & Filter Container */}
      {/* Does not appear on the search page. Access it from clicking on a category tile */}
      {/* https://localhost:8000/furniture/living-rooms/sets */}
      <Wrapper>
        {/* showLabel is actually isMobile and probably shouldnt have it's own state */}
        {/* If the viewport is mobile then display the sticky stats/sort/delivery */}
        {showLabel && (
          <Label container id="header-filters-search" justifyContent="space-between" alignItems="center">
            <Stats nbHits={refinedSearchResults?.content?.nbHits} searching={searching} />
            <SortFilter
              onClick={() => setOpenModalSidebar(true)}
              data-testid={generateTestId('search-results', 'sort-filter-btn')}
            >
              <span>Sort &amp; Filter</span>
              {filtersQuantity > 0 && <CircleCounter quantity={filtersQuantity} />}
            </SortFilter>
          </Label>
        )}
        <Drawer open={openModalSidebar} onClose={() => setOpenModalSidebar(false)}>
          <SidebarMobileWrapper>
            <Sidebar
              searching={searching}
              searchState={searchState}
              applyStateChange={applyStateChange}
              refinementList={refinementList}
              matchPath={matchPath}
              refinedSearchResults={refinedSearchResults}
              getUrl={getUrl}
              stats={stats}
              clearState={clearState}
              setOpenModalSidebar={setOpenModalSidebar}
              openModalSidebar={openModalSidebar}
            />
          </SidebarMobileWrapper>
        </Drawer>

        <LeftBarWrapper>
          <Sidebar
            searching={searching}
            searchState={searchState}
            applyStateChange={applyStateChange}
            refinementList={refinementList}
            matchPath={matchPath}
            refinedSearchResults={refinedSearchResults}
            getUrl={getUrl}
            stats={stats}
            clearSortBy={clearSortBy}
          />
        </LeftBarWrapper>
        <Paper container sx={{ width: '100%' }}>
          <CustomDisconnectedCurrentRefinements
            clearState={clearState}
            refinementList={refinementList}
            searchState={searchState}
            applyStateChange={applyStateChange}
            getUrl={getUrl}
          />
          <Hits
            searching={searching}
            source={source}
            captureSentryError={captureSentryError}
            productType={productType}
            hits={preparedHits}
            filtersChanged={filtersChanged}
          />

          <div className="pagination">
            <Pagination
              padding={2}
              nbPages={refinedSearchResults?.content?.nbPages}
              currentPage={searchState.page}
              applyStateChange={applyStateChange}
            />
          </div>
        </Paper>
      </Wrapper>
    </SearchResultsContext.Provider>
  )
}

SearchResults.propTypes = {
  displayFilters: PropTypes.array,
  matchPath: PropTypes.any,
  onFiltersChange: PropTypes.func,
  plpBanners: PropTypes.any,
  productType: PropTypes.any,
  queryData: PropTypes.any,
  searchResults: PropTypes.shape({
    content: PropTypes.any,
    state: PropTypes.shape({
      index: PropTypes.any,
    }),
    _originalResponse: PropTypes.object,
  }),
  source: PropTypes.string,
  visualFilterData: PropTypes.object,
}

export default SearchResults
