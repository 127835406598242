// dependencies
import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import { graphql } from 'gatsby'
import StrapiLink from './Link'
import StrapiImageGallery from './ImageGallery/ImageGallery'

const Wrapper = styled('div')(({ theme, SpacingDesktop, SpacingMobile }) => ({
  color: theme.palette.text.primary,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: 'transparent',
  },
  paddingTop: SpacingMobile?.TopPadding ?? 0,
  paddingBottom: SpacingMobile?.BottomPadding ?? 0,
  [theme.breakpoints.up('md')]: {
    paddingTop: SpacingDesktop?.TopPadding ?? 0,
    paddingBottom: SpacingDesktop?.BottomPadding ?? 0,
  },
}))

const ContentStyles = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: 0,
  margin: '10px 0',
  [theme.breakpoints.up('md')]: {
    maxWidth: '850px',
  },
}))

const HeadingStyles = styled(Typography)(({ theme, desktopOrMobile }) => ({
  color: '#07263B',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: '30px',
  fontWeight: 700,
  lineHeight: 1,
  textTransform: 'none',
  marginBottom: '10px',
  [theme.breakpoints.up('md')]: {
    fontSize: '30px !important',
    display: desktopOrMobile === 'desktop' ? 'block' : 'none',
    marginTop: '10px',
    marginBottom: '20px',
  },
  [theme.breakpoints.down('md')]: {
    display: desktopOrMobile === 'mobile' ? 'block' : 'none',
  },
}))

const SubheadingStyles = styled(Typography)(({ theme }) => ({
  color: '#07263B',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontSize: '15px',
  fontWeight: 700,
  lineHeight: 1,
  marginBottom: '20px',
  marginTop: '10px',
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    marginTop: 0,
  },
}))

const DescriptionStyles = styled(Typography)(({ theme, isSubheading }) => ({
  fontSize: '14px',
  marginBottom: '20px',
  marginTop: isSubheading ? 0 : '10px',
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
    marginTop: 0,
  },
}))

const LinksWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: '0 25px',
  flexWrap: 'wrap',
}))

const LinkStyles = styled(StrapiLink)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 700,
  color: '#0053A0 !important',
  width: 'fit-content',
  '& svg.link-arrow-next': {
    right: '-1.1rem',
    width: '15px',
    height: '15px',
    top: '55%',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '16px',
  },
}))

const StrapiCollectionTileImageGallery = ({ data }) => {
  const { Link, SecondaryLink, ImageGallery, Heading, SubHeading, Description, SpacingDesktop, SpacingMobile } =
    data?.CollectionTileImageGallery || data

  return (
    <Wrapper SpacingDesktop={SpacingDesktop} SpacingMobile={SpacingMobile}>
      {Heading && (
        <HeadingStyles gutterBottom variant="h2" component="h2" desktopOrMobile="mobile">
          {Heading}
        </HeadingStyles>
      )}
      {ImageGallery && (
        <StrapiImageGallery
          data={ImageGallery}
          imageGalleryProps={{
            disableSpacing: true,
            disableHeader: true,
            disableScrollbar: true,
          }}
        />
      )}
      <ContentStyles>
        <div>
          {Heading && (
            <HeadingStyles gutterBottom variant="h2" component="h2" desktopOrMobile="desktop">
              {Heading}
            </HeadingStyles>
          )}
          {SubHeading && <SubheadingStyles>{SubHeading}</SubheadingStyles>}
          {Description && (
            <DescriptionStyles gutterBottom variant="body1" isSubheading={!!SubHeading}>
              {Description}
            </DescriptionStyles>
          )}
        </div>
        <LinksWrapper>
          {Link && <LinkStyles data={Link} arrowNext />}
          {SecondaryLink && <LinkStyles data={SecondaryLink} arrowNext />}
        </LinksWrapper>
      </ContentStyles>
    </Wrapper>
  )
}

StrapiCollectionTileImageGallery.propTypes = {
  data: PropTypes.object.isRequired,
}

export default StrapiCollectionTileImageGallery

export const StrapiCollectionTileImageGalleryFragment = graphql`
  fragment StrapiCollectionTileImageGalleryFragment on StrapiCollectionTileImageGallery {
    id
    testId
    Description
    Heading
    Title
    Link {
      Link {
        ...StrapiLinkFragment
      }
    }
    SecondaryLink {
      Link {
        ...StrapiLinkFragment
      }
    }
    SubHeading
    ImageGallery {
      ImageGallery {
        ...ImageGalleryFragment
      }
    }
    SpacingDesktop {
      TopPadding
      BottomPadding
    }
    SpacingMobile {
      TopPadding
      BottomPadding
    }
  }
`
