import { styled } from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import StrapiImage from '../Image'
import StrapiLink from '../Link'

export const TileWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  boxSizing: 'border-box',
  width: '100%',
  height: '100%',
  '&:hover': {
    fontWeight: '700',
  },
}))

export const TitleText = styled('span')(
  ({ theme, width, mobileWidth, isSelected, isActiveState, isDisplayFilter, name, shouldUseMinContent }) => {
    const isOneWord = name?.split(' ').length === 1
    const TitleTextWidthAuto = width === 'auto' ? '100%' : `${width}px`
    const TitleTextWidth = isActiveState ? 'fit-content' : TitleTextWidthAuto
    const TitleTextWidthAutoMobile = mobileWidth === 'auto' ? '100%' : `${mobileWidth}px`
    const TitleTextWidthMobile = isActiveState ? 'auto' : TitleTextWidthAutoMobile

    return {
      textAlign: isActiveState && !shouldUseMinContent ? 'left' : 'center',
      display: 'inline-block',
      width: TitleTextWidth,
      fontSize: isDisplayFilter ? '18px' : '16px',
      lineHeight: '1.2rem',
      color: !isSelected ? '#003566' : theme.palette.primary.main,
      fontWeight: isActiveState ? '600' : '500',
      marginTop: isActiveState ? '0' : '20px',
      verticalAlign: 'middle',
      [theme.breakpoints.down('md')]: {
        width: TitleTextWidthMobile,
        fontSize: isDisplayFilter ? '12px' : '14px',
        marginTop: isActiveState ? '1px' : '10px',
        lineHeight: '1rem',
        overflow: isOneWord && 'hidden',
        textOverflow: isOneWord && 'ellipsis',
      },
    }
  },
)

export const CircleWrapper = styled('div')(
  ({ theme, width, mobileWidth, ImageIncludesBackground, noBorder, border, isSelected, isActiveState }) => {
    const desktopBorder = isSelected || isActiveState ? '4px solid #0053a0' : '2px solid #BDBDBD'

    return {
      width: width === 'auto' ? '100%' : `${width}px`,
      aspectRatio: '1 / 1',
      borderRadius: '50%',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: '1',
      border: noBorder ? null : desktopBorder,
      backgroundColor: ImageIncludesBackground ? null : '#F7F7F7',
      [theme.breakpoints.down('md')]: {
        width: mobileWidth === 'auto' ? '100%' : `${mobileWidth}px`,
        border: noBorder ? null : border,
      },
    }
  },
)

export const ImgStyle = styled(StrapiImage)(({ theme, ImageIncludesBackground }) => ({
  backgroundColor: 'transparent',
  width: '100%',
  height: '100%',
  padding: ImageIncludesBackground ? null : '15%',
}))

export const StrapiLinkStyle = styled(StrapiLink)(() => ({
  '&:focus': {
    outline: 'none',
  },
}))

export const CheckCircleIconStyle = styled(CheckCircleIcon)(({ theme }) => ({
  width: '20px',
  height: '20px',
  verticalAlign: 'text-top',
  marginRight: '4px',
  [theme.breakpoints.down('md')]: {
    width: '18px',
    height: '18px',
  },
}))

export const HeadingContainerStyle = styled('div')(() => ({
  textAlign: 'center',
  marginTop: '15px',
  borderRadius: '4px',
  width: '150px',
}))

export const HeadingBackgroundStyle = styled('div')(({ theme, shouldUseMinContent }) => ({
  backgroundColor: '#F2F6FA',
  padding: '6px 10px',
  margin: '0 auto',
  textAlign: 'left',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  width: shouldUseMinContent ? '100%' : '',
  [theme.breakpoints.down('md')]: {
    padding: '2px 3px',
    width: shouldUseMinContent ? 'min-content' : '',
  },
}))

export const OnSaleWrapper = styled('span')(({ theme }) => ({
  position: 'relative',
  right: '72px',
  [theme.breakpoints.down('md')]: {
    right: '54px',
  },
  '& .on-sale-flag': {
    backgroundColor: '#E11F21',
    borderRadius: '10px 2px 4px 0px',
    fontWeight: '600',
    height: '28px',
    position: 'absolute',
    top: '7px',
    width: '56px',
    zIndex: '2',
    '&:hover': {
      fontWeight: '600',
    },
    '& p': {
      color: 'white',
      fontSize: '16px',
      padding: '2px 10px',
    },
    '&:after': {
      backgroundColor: '#A3120F',
      height: '15px',
      left: '7px',
      top: '14px',
      width: '10px',
    },
    [theme.breakpoints.down('md')]: {
      height: '16px',
      left: '10px',
      width: '34px',
      '& p': {
        color: 'white',
        fontSize: '10px',
        fontWeight: '700',
        padding: '0px 6px',
      },
    },
  },
  '& .on-sale-flag-shadow': {
    backgroundColor: '#A3120F',
    height: '48px',
    left: '14px',
    position: 'absolute',
    transform: 'rotate(125deg)',
    top: '12px',
    width: '32px',
    [theme.breakpoints.down('md')]: {
      left: '28px',
      top: '8px',
      width: '12px',
    },
  },
}))
