// dependecies
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Paper, ButtonBase, makeStyles, Typography } from '@material-ui/core'
import { isArray, isEmpty } from 'lodash'
// context
import { useStrapiContext } from '@context/strapiContext'
// hooks
import useHoverElevation from '@hooks/useHoverElevation'
// components
import StrapiButton from './Button'
import { getLinkProps } from './Link'

const useStyles = makeStyles(theme => ({
  wrapper: ({ disclaimer, noTransformation }) => ({
    // border: `2px dashed ${buttonColor}`,
    cursor: 'pointer',
    borderRadius: '4px',
    position: 'relative',
    padding: '0',
    /* if a disclaimer is included, increase the available space by 50px */
    paddingBottom: disclaimer ? '100px' : '50px',
    height: '100%',
    maxWidth: '486px',
    transition: theme.transitions.create(['all'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.short,
    }),
    '&:hover': {
      transform: !noTransformation ? ' scale(1.01)' : null,
    },
    '& *:focus': {
      outline: 'none !important',
    },
  }),
  couponBorder: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
    zIndex: 2,
  },
  borderRect: {
    borderRadius: '10px',
  },
  disclaimer: {
    fontStyle: 'italic',
    display: 'block',
    padding: theme.spacing(1),
    textAlign: 'center',
    bottom: 50,
    position: 'absolute',
    width: '100%',
  },
  link: {
    display: 'flex',
    '&:hover': {
      textDecoration: 'none',
    },
    zIndex: 3,
  },
  couponBtn: {
    position: 'absolute',
    width: 'calc(100% - 6px)',
    bottom: 3,
    left: 3,
    padding: '10px 0',
    // margin: '0 5px',
  },
  content: ({ backgroundImage, alignment }) => ({
    padding: '3.4px',
    textAlign: alignment,
    minHeight: '150px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    ...(backgroundImage?.url
      ? {
          backgroundImage: backgroundImage?.url ? `url(${backgroundImage.url})` : null,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }
      : {}),
    '& > *:not(:first-child)': {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  }),
}))

const StrapiCoupon = ({ data, noTransformation = false }) => {
  const { onMouseEnter, onMouseLeave, elevation } = useHoverElevation()
  const { disabledCoupons } = useStrapiContext()

  const __html = data?.Content?.data?.childMarkdownRemark?.html
  const link = data?.Button?.Button?.Link?.Link
  const buttonProps = link ? getLinkProps(data, link, { category: 'coupon' }) : {}
  const buttonColor = data?.Button?.Button?.BackgroundColor?.ColorHex

  const classes = useStyles({
    backgroundImage: data?.BackgroundImage,
    disclaimer: data?.Disclaimer,
    alignment: data?.ContentAlignment,
    noTransformation,
  })

  const isDisabled = useMemo(() => {
    const coupons = disabledCoupons?.data
    if (isArray(coupons) && !isEmpty(coupons)) {
      const couponId = data?.testId?.split(':')?.[1] ?? ''
      return coupons.some(({ id }) => id === couponId)
    }
    return false
  }, [disabledCoupons, data])

  if (isDisabled) {
    return null
  }

  const elevationTransformation = !noTransformation
    ? {
        onMouseEnter,
        onMouseLeave,
        elevation,
      }
    : {
        elevation: 2,
      }

  return (
    <Paper {...elevationTransformation} className={classes.wrapper} data-testid={data?.testId} id="couponContainer">
      <svg
        className={classes.couponBorder}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <rect
          className={classes.borderRect}
          fill="none"
          stroke={buttonColor}
          stroke-width="1.5"
          stroke-dasharray="3 2"
          strokeDashoffset="0"
          width="100"
          height="100"
          rx="2"
          ry="2"
        />
      </svg>
      {link ? (
        <ButtonBase {...buttonProps} className={classes.link}>
          <div className={classes.content} dangerouslySetInnerHTML={{ __html }} />
        </ButtonBase>
      ) : (
        <div className={classes.content} dangerouslySetInnerHTML={{ __html }} />
      )}
      {data?.Disclaimer && (
        <Typography className={classes.disclaimer} variant="body2">
          {data.Disclaimer}
        </Typography>
      )}
      <StrapiButton data={data?.Button?.Button} trackingData={buttonProps.trackingData} className={classes.couponBtn} />
    </Paper>
  )
}

StrapiCoupon.propTypes = {
  data: PropTypes.object.isRequired,
  noTransformation: PropTypes.bool,
}

export default StrapiCoupon

export const StrapiCouponFragment = graphql`
  fragment StrapiCouponFragment on StrapiCoupon {
    id
    testId
    Disabled
    Disclaimer
    Title
    ContentAlignment
    BackgroundImageAltText
    BackgroundImage {
      ...StrapiImageFragment
    }
    Button {
      Button {
        ...StrapiButtonFragment
      }
    }
    Content {
      data {
        childMarkdownRemark {
          id
          html
        }
      }
    }
  }
`
