import { ImageList, ImageListItem, ImageListItemBar, styled } from '@mui/material'
import InnerHtml from '@components/shared/InnerHtml'
import StrapiLink from '@templates/strapi-cms/content-types/Link'
import StrapiImage from '@templates/strapi-cms/content-types/Image'

export const ImageListStyles = styled(ImageList)(({ theme, rows }) => ({
  transform: 'translateZ(0)',
  gridTemplateColumns: 'repeat(12, 1fr) !important',
  gap: '15px !important',
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: 'repeat(8, 1fr) !important',
    gridTemplateRows: `repeat(${rows}, 1fr)`,
    gap: '7px !important',
  },
}))

export const ImageListItemStyles = styled(ImageListItem)(({ theme, backgroundcolor, itemratio }) => ({
  aspectRatio: itemratio?.aspectRatio,
  display: 'grid',
  backgroundColor: backgroundcolor,
  [theme.breakpoints.down('md')]: {
    height: itemratio?.fullHeight && '100% !important',
  },
}))

export const InnerHtmlStyles = styled(InnerHtml)(({ theme, fontcolor }) => ({
  '& p': {
    fontSize: '23px',
    lineHeight: 'inherit',
    fontWeight: '600',
    whiteSpace: 'normal',
    color: fontcolor?.ColorHex ? fontcolor.ColorHex : 'black',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
      fontWeight: '600',
      lineHeight: 'normal',
    },
  },
}))

export const StrapiLinkSubtitleStyles = styled('div')(({ theme, fontcolor }) => ({
  zIndex: 2,
  cursor: 'pointer',
  fontSize: '14px',
  color: fontcolor?.ColorHex ? fontcolor.ColorHex : 'black',
  textDecoration: 'underline',
  whiteSpace: 'normal',
  lineHeight: '1.5rem',
  [theme.breakpoints.down('sm')]: {
    fontSize: '11px',
    lineHeight: 'normal',
  },
}))

export const StrapiLinkTileStyles = styled(StrapiLink)(() => ({
  display: 'block',
  position: 'absolute',
  height: '100%',
  width: '100%',
  top: '0px',
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 1,
  cursor: 'pointer',
  '& *': {
    display: 'none',
  },
}))

export const SectionStyles = styled('section')(() => ({
  height: '100%',
}))

export const ImageListItemBarStyles = styled(ImageListItemBar)(({ theme }) => ({
  width: '100%',
  height: '100%',
  '& .MuiImageListItemBar-titleWrap': {
    height: '100%',
  },
  '& .MuiImageListItemBar-title': {
    height: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiImageListItemBar-titleWrap': {
      padding: '6px 8px',
    },
    '& p ': {
      lineHeight: '.9rem !important',
      paddingBottom: '3px !important',
    },
  },
}))

export const StrapiImageStyles = styled(StrapiImage)(() => ({
  objectFit: 'fill !important',
  '& picture img': {
    objectFit: 'fill !important',
  },
}))
