import React from 'react'
import { saveLocalStorage } from '@helpers/storage'
import { number, func } from 'prop-types'
import '@assets/css/components/product/product-pagination.sass'
import classNames from 'classnames'

export class Pagination extends React.Component {
  updatePage(newPage) {
    const { currentPage, applyStateChange } = this.props
    const page = newPage ?? currentPage
    saveLocalStorage('rtg_scroll', {})
    applyStateChange('page', page)
    if (typeof window !== 'undefined') {
      window.scrollTo(0, 0)
    }
    try {
      document.getElementById('productResultsWrapper').focus()
      window.scrollTo(0, 0)
    } catch (error) {}
  }

  render() {
    const { nbPages, padding, currentPage } = this.props
    const start = currentPage - padding > 0 ? currentPage - padding : 1
    const end = currentPage + padding + 1 < nbPages ? currentPage + padding + 1 : nbPages
    const pageRange = Array.from({ length: end + 1 - start }, (a, b) => b + start)
    const isCurrent = page => (currentPage === page ? true : null)

    const generateHrefValue = page => {
      try {
        const { href } = window.location

        const { search, origin, pathname } = new URL(href)
        const params = new URLSearchParams(search)

        // FD-2826: parameter removal is performed because, when **page = 1**,
        // the parameter is not displayed by react-instantsearch
        if (page === 1) {
          params.delete('page')
        } else {
          params.set('page', page)
        }

        return `${origin}${pathname.endsWith('/') ? pathname.slice(0, -1) : pathname}${
          params.toString().length > 0 ? '?' : ''
        }${params}`
      } catch {
        return '#'
      }
    }

    const pageOptions = pageRange.map(page => (
      <li key={page}>
        <a
          href={generateHrefValue(page)}
          onClick={e => {
            e.preventDefault()
            this.updatePage(page)
          }}
          className={classNames({ 'active-page': isCurrent(page) || !isCurrent(page) === 1 })}
          aria-current={isCurrent(page)}
        >
          <span className="hide508">Page</span>
          {page}
        </a>
      </li>
    ))

    if (nbPages <= 1) {
      return null
    }

    return (
      <div className="plp-pagination">
        <nav role="navigation" aria-label="Pagination">
          {currentPage > 1 && (
            <a
              href={generateHrefValue(currentPage - 1)}
              className="arrow"
              aria-label="Next Page"
              onClick={e => {
                e.preventDefault()
                this.updatePage(currentPage - 1)
              }}
            >
              <span aria-hidden="true">‹</span>
            </a>
          )}
          <ul>{pageOptions}</ul>
          {currentPage < nbPages && (
            <a
              href={generateHrefValue(currentPage + 1)}
              className="arrow"
              aria-label="Next Page"
              onClick={e => {
                e.preventDefault()
                this.updatePage(currentPage + 1)
              }}
            >
              <span aria-hidden="true">›</span>
            </a>
          )}
        </nav>
      </div>
    )
  }
}

Pagination.propTypes = {
  nbPages: number,
  padding: number,
  currentPage: number,
  applyStateChange: func,
}

export default Pagination
